import { createAsyncThunk,createSlice } from '@reduxjs/toolkit';
import { ApiResp } from '../../types/api-resp.type';
import { CreateMediaSupportEntity, MediaSupportEntity, MediaSupportMethod, createMediaSupportApi, getMediaSupportApi, getMediaSupportByIdApi, getMediaSupportMethodsApi, updateMediaSupportByIdApi } from '../../apis/media-support.api';

export interface MediaSupportSlice {
    mediaSupports: MediaSupportEntity[],
    mediaSupportMethods: MediaSupportMethod[];
    selectedMediaSupport: MediaSupportEntity | null
}

const initialState: MediaSupportSlice = {
    mediaSupports: [],
    mediaSupportMethods: [],
    selectedMediaSupport: null
};

export const createMediaSupport = createAsyncThunk(`mediaSupports/create`, async (payload: CreateMediaSupportEntity, { dispatch }) => {
    const resp = await createMediaSupportApi(payload);
    dispatch(getMediaSupportList())

    return resp.result;
});

export const getMediaSupportList = createAsyncThunk('mediaSupports/list', async () => {
    const resp = await getMediaSupportApi();

    return resp.result.items;
});

export const getMediaSupportById = createAsyncThunk(`mediaSupports/selectedMediaSupport`, async (mediaSupportId: string) => {
    const resp = await getMediaSupportByIdApi(mediaSupportId);

    return resp.result;
})

export const getMediaSupportMethods = createAsyncThunk('mediaSupports/mediaSupportMethods', async () => {
    const resp = await getMediaSupportMethodsApi();

    return resp.result.items;
});

export const updateMediaSupportById = createAsyncThunk(
    `mediaSupports/updateMediaSupportById`,
    async(payload: {
        id: string,
        mediaSupport: MediaSupportEntity
    }, { dispatch }) => {
    const resp = await updateMediaSupportByIdApi(payload.id, payload.mediaSupport);

    dispatch(getMediaSupportById(payload.id));

    return resp.result;
})

const mediaSupportSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createMediaSupport.fulfilled, (state, action) => {});
    builder.addCase(getMediaSupportList.fulfilled, (state, action) => {
        state.mediaSupports = action.payload
    });
    builder.addCase(getMediaSupportMethods.fulfilled, (state, action) => {
        state.mediaSupportMethods = action.payload
    });
    builder.addCase(getMediaSupportById.fulfilled, (state, action) => {
        state.selectedMediaSupport = action.payload
    });
  },
});

export default mediaSupportSlice.reducer;
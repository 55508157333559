import { APP_CONFIG } from '../config';
import { ApiListResp, ApiResp } from '../types/api-resp.type';
import { HttpApi } from './http/http.api';
import { Merchant } from './merchant.api';

export interface Coupon {
    id: string;
    merchant_id: string;
    title: string;
    img_url: string | null;
    description: string | null;
    start_at: string | null;
    end_at: string | null;
    discount_type: 'Money' | 'Percent';
    discount_value: number;
    min_order_required: number | null;
    max_discount_value: number | null;
    valid_times: number | null;
    time_requirement: 'Required' | 'NoRequired' | 'RequiredStartAtOnly';
    merchant?: Merchant;
}

export interface CouponFilter {
    search?: string;
    start_at?: string;
    merchant_id?: string;
}

export const getCouponListApi = (filter: CouponFilter): Promise<ApiListResp<Coupon>> => {

    let _filter: {[key: string]: string} = {}
    if (filter.merchant_id) {
        _filter['merchant_id'] = filter.merchant_id;
    }

    if (filter.start_at) {
        _filter['start_at'] = filter.start_at;
    }

    if (filter.search) {
        _filter['search'] = filter.search;
    }
    
    const query = new URLSearchParams(_filter).toString();

    return HttpApi.get<ApiListResp<Coupon>>(`${APP_CONFIG.API_URL}/coupons?${query}`).then(({ data }) => data);
}
    
export const getCouponByIdApi = async (merchantId: string): Promise<ApiResp<Coupon>> => 
    HttpApi.get<ApiResp<Coupon>>(`${APP_CONFIG.API_URL}/coupons/${merchantId}`).then(({ data }) => data);

export const createCouponApi = async (payload: Coupon): Promise<ApiResp<Coupon>> => 
    HttpApi.post<ApiResp<Coupon>>(`${APP_CONFIG.API_URL}/coupons`, payload).then(({ data }) => data);

export const updateCouponByIdApi = async (id: string, payload: Coupon): Promise<ApiResp<Coupon>> => 
    HttpApi.patch<ApiResp<Coupon>>(`${APP_CONFIG.API_URL}/coupons/${id}`, payload).then(({ data }) => data);

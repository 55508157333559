import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux.hook";
import { Box, Button, Chip, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Node, addEdge, applyEdgeChanges, applyNodeChanges, DefaultEdgeOptions, Edge, FitViewOptions, NodeTypes, OnConnect, OnEdgesChange, OnNodeDrag, OnNodesChange, ReactFlow, useEdgesState, useNodesState, NodeProps, Background, BackgroundVariant, Controls, EdgeTypes, BuiltInEdge, Connection } from '@xyflow/react';
import dayjs from "dayjs";
import { createSeedingContent, createSeedingContentLink, deleteSeedingContent, getSeedingScenarioDetail, getSeedingScenarioList, updateSeedingContent } from "../../../stores/slices/seeding-scenario.slice";
import NodeTypeButton from "./components/node-type-button.component";
import CustomNode from "./components/custom-node.component";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
    ClassicEditor,
    Bold,
    Essentials,
    Heading,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    MediaEmbed,
    Paragraph,
    Table,
    Undo
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import '@xyflow/react/dist/style.css';
import StartNode from "./components/start-node.component";
import { SeedingContentLabel, SeedingContentType } from "../../../apis/seeding-scenario.api";
import CustomEdge from "./components/custom-edge.component";


interface NodeOption {
    label: string;
    value: SeedingContentType;
}


const nodeOptions: NodeOption[] = [
    {
        label: 'Đăng bài',
        value: 'post'
    },
    {
        label: 'Comment',
        value: 'comment'
    },
    {
        label: 'Reply Comment',
        value: 'reply'
    }
];

const nodeTypes: NodeTypes = {
    start: StartNode,
    custom: CustomNode
};

const edgeTypes: EdgeTypes = {
    custom: CustomEdge
}


const initialNodes: Node[] = [];

const initialEdges: Edge[] = [];

const fitViewOptions: FitViewOptions = {
    padding: 0.2,
};

const defaultEdgeOptions: DefaultEdgeOptions = {
    animated: true,
};

const onNodeDrag: OnNodeDrag = (_, node) => {
    // console.log('drag event', node.data);
};

const SeedingScenarioDetailPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { scenarioId } = useParams();

    const scenario = useAppSelector((state) => state.seedingScenario.selectedItem);
    const [nodes, setNodes] = useState<Node[]>(initialNodes);
    const [edges, setEdges] = useState<Edge[]>(initialEdges);
    const [lastNodePosition, setLastNodePosition] = useState<{ x: number, y: number }>({ x: 5, y: 5 });
    const [selectedNode, setSelectedNode] = useState<Node | undefined>();
    const [selectedEdge, setSelectedEdge] = useState<Edge | undefined>();
    const [editorData, setEditorData] = useState<string | undefined>();
    const [lastNodeId, setLastNodeId] = useState<number>(1);

    const [sourceNodeId, setSourceNodeId] = useState<string | undefined>(undefined);
    const [targetNodeId, setTargetNodeId] = useState<string | undefined>(undefined);

    const onNodesChange: OnNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [setNodes],
    );

    const onEdgesChange: OnEdgesChange = useCallback(
        (changes) => {
            setEdges((eds) => applyEdgeChanges(changes, eds))
        },
        [setEdges],
    );

    const onNodeHandleMouseDown = (nodeId: string) => {
        setSourceNodeId(nodeId);
    }

    const onNodeHandleMouseUp = (nodeId: string) => {
        setTargetNodeId(nodeId);
    }

    const onConnect = (connection: Connection) => {

        console.log(connection)

        const targetNode = nodes.find(node => node.id === connection.target);

        if (!targetNode || !scenarioId) {
            return;
        }

        console.log(sourceNodeId);
        console.log(connection.source);
        console.log(sourceNodeId == connection.source);

        if (sourceNodeId != connection.source) {
            const sourceHandle = connection.targetHandle ? connection.targetHandle.replace('target', 'source') : connection.sourceHandle;
            const targetHandle = connection.sourceHandle ? connection.sourceHandle.replace('source', 'target') : connection.targetHandle;

            connection.sourceHandle = sourceHandle;
            connection.targetHandle = targetHandle;

            const sourceId = connection.target;
            const targetId = connection.source;

            connection.source = sourceId;
            connection.target = targetId;
        }

        setSourceNodeId(undefined);
        setTargetNodeId(undefined);

        console.log(connection)

        const edge = {
            ...connection,
            animated: true,
            id: `xy-edge__${connection.source}${connection.sourceHandle}-${connection.target}${connection.targetHandle}`,
            label: `${targetNode?.data?.afterTime ? targetNode.data.afterTime : 2} ${targetNode?.data?.afterTimeUnit ? targetNode.data.afterTimeUnit : 'second'}`
        }
        setEdges([...edges, edge]);
        setNodes(nodes.map(node => {
            if (node.id !== targetNode.id) {
                return node;
            }

            return {
                ...node,
                data: {
                    ...node.data,
                    afterTime: targetNode?.data?.afterTime ? targetNode.data.afterTime : 2,
                    afterTimeUnit: targetNode?.data?.afterTimeUnit ? targetNode.data.afterTimeUnit : 'second'
                }
            }
        }));

        dispatch(createSeedingContentLink({
            scenarioId: parseInt(scenarioId),
            contentLink: {
                scenario_id: parseInt(scenarioId),
                edge_id: edge.id,
                edge_type: 'builtin',
                source_id: parseInt(edge.source),
                target_id: parseInt(edge.target),
                animated: edge.animated,
                label: null,
                source_handle: edge.sourceHandle as string,
                target_handle: edge.targetHandle as string,
            }
        }));
    }

    const onNodeDragStop: OnNodeDrag = (_, node) => {
        if (!scenarioId) {
            return
        }

        dispatch(updateSeedingContent({
            scenarioId: parseInt(scenarioId),
            contentNodeId: parseInt(node.id),
            content: {
                node_position_x: node.position.x,
                node_position_y: node.position.y
            }
        }))
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Delete') {
            if (!scenarioId || !selectedNode) {
                return
            }

            setNodes((nds) => nds.filter((node) => node.id !== selectedNode.id));
            dispatch(deleteSeedingContent({
                scenarioId: parseInt(scenarioId),
                contentNodeId: parseInt(selectedNode.id)
            }));
            setSelectedNode(undefined);
        }
    }

    const onNodeClick = (event: React.MouseEvent, node: Node) => {
        event.stopPropagation();  // Prevents deselecting on pane click

        if (node.type == 'start') {
            return
        }

        setSelectedNode(node);
        setSelectedEdge(undefined);
        setEditorData(node?.data?.content ? node?.data?.content?.toString() : '');
        setNodes(nodes.map(nd => {
            if (nd.id !== node.id) {
                return nd;
            }

            return {
                ...nd,
                style: {
                    ...nd.style,
                    backgroundColor: 'beige'
                }
            }
        }));
    };

    const onEdgeClick = (event: React.MouseEvent, edge: Edge) => {
        event.stopPropagation();  // Prevents deselecting on pane click
        setSelectedEdge(edge);
        setSelectedNode(undefined);
    }

    const onPaneClick = () => {
        setSelectedNode(undefined);
        setNodes(nodes.map(node => {
            return {
                ...node,
                style: { 
                    ...node.style, 
                    backgroundColor: '#ffffff' 
                },
            }
        }));
    }

    useEffect(() => {
        if (scenarioId) {
            dispatch(getSeedingScenarioDetail(parseInt(scenarioId))).unwrap().then((seedingScenario) => {
                if (!seedingScenario.content?.length) {
                    return;
                }

                const nds: Node[] = [];
                let maxNodeId: number = 1;

                for (const content of seedingScenario.content) {
                    if (maxNodeId < parseInt(content.node_id)) {
                        maxNodeId = parseInt(content.node_id);
                    }

                    if (content.type == 'start') {
                        nds.push({
                            id: content.node_id,
                            type: 'start',
                            data: { 
                                label: 'Start', 
                                content: '',
                                onNodeHandleMouseUp: onNodeHandleMouseUp,
                                onNodeHandleMouseDown: onNodeHandleMouseDown
                            },
                            position: {
                                x: content.node_position_x,
                                y: content.node_position_y
                            }
                        })
                    }
                    else {
                        nds.push({
                            id: content.node_id,
                            type: 'custom',
                            data: {
                                label: SeedingContentLabel[content.type],
                                content: content.content,
                                afterTime: content.after_time,
                                afterTimeUnit: content.after_time_unit,
                                onNodeHandleMouseUp: onNodeHandleMouseUp,
                                onNodeHandleMouseDown: onNodeHandleMouseDown
                            },
                            style: { background: 'white' },
                            position: {
                                x: content.node_position_x,
                                y: content.node_position_y
                            }
                        })
                    }
                }

                setNodes(nds);
                setLastNodeId(maxNodeId);

                if (seedingScenario?.content_links?.length) {
                    setEdges((seedingScenario?.content_links?.map((contentLink) => {

                        const targetNode = nds.find((nd) => nd.id === contentLink.target_id.toString());

                        return {
                            id: contentLink.edge_id,
                            source: contentLink.source_id.toString(),
                            target: contentLink.target_id.toString(),
                            animated: contentLink.animated,
                            sourceHandle: contentLink.source_handle,
                            targetHandle: contentLink.target_handle,
                            label: `${targetNode?.data?.after_time ? targetNode.data.after_time : 2} ${targetNode?.data?.after_time_unit ? targetNode.data.after_time_unit : 'second'}`
                        }
                    })))
                }
            })
        }

    }, []);

    return (
        <>
            <div style={{
                width: 'calc(100% - 400px)',
                height: '790px',
            }}>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onKeyDown={onKeyDown}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onNodeClick={onNodeClick}
                    onEdgeClick={onEdgeClick}
                    onConnect={onConnect}
                    onNodeDrag={onNodeDrag}
                    onNodeDragStop={onNodeDragStop}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                    onPaneClick={onPaneClick}
                    fitView
                    fitViewOptions={fitViewOptions}
                    defaultEdgeOptions={defaultEdgeOptions}
                >
                    <Background id="1" color="#fc9797" gap={25} variant={BackgroundVariant.Dots} size={2} />
                    <Controls />
                </ReactFlow>
            </div>
            <Paper elevation={2} sx={{
                position: 'fixed',
                width: '400px',
                bottom: '0px',
                right: '0px',
                top: '100px',
                padding: '20px'
            }}>
                {
                    selectedNode ?
                        <Grid container spacing={2} sx={{ padding: '20px 10px' }}>
                            <Grid item xs={12}>
                                <Typography variant="h2">{selectedNode.id}. {selectedNode.data.label?.toString()}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                    <Typography variant="h4">Thời gian thực hiện</Typography>
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        <Box sx={{ width: '50%' }}>
                                            <TextField
                                                variant="outlined"
                                                label='Sau hành động trước'
                                                fullWidth
                                                value={selectedNode.data.afterTime}
                                                onChange={(event) => {
                                                    setSelectedNode({
                                                        ...selectedNode,
                                                        data: {
                                                            ...selectedNode.data,
                                                            afterTime: parseInt(event.target.value)
                                                        }
                                                    });

                                                    setNodes(nodes.map(node => {
                                                        if (node.id !== selectedNode.id) {
                                                            return node;
                                                        }

                                                        return {
                                                            ...node,
                                                            data: {
                                                                ...node.data,
                                                                afterTime: parseInt(event.target.value)
                                                            }
                                                        }
                                                    }));

                                                    const edge = edges.find((edge) => edge.target == selectedNode.id);
                                                    if (edge) {
                                                        setEdges(edges.map(edg => {
                                                            return {
                                                                ...edg,
                                                                label: `${event.target.value}${selectedNode?.data?.afterTimeUnit ? selectedNode?.data?.afterTimeUnit : 'second'}`
                                                            }
                                                        }))
                                                    }

                                                    if (!scenarioId) {
                                                        return;
                                                    }

                                                    dispatch(updateSeedingContent({
                                                        scenarioId: parseInt(scenarioId),
                                                        contentNodeId: parseInt(selectedNode.id),
                                                        content: {
                                                            after_time: parseInt(event.target.value)
                                                        }
                                                    }));
                                                }}
                                            ></TextField>
                                        </Box>
                                        <Box sx={{ width: '50%' }}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={selectedNode.data.afterTimeUnit}
                                                    label="Age"
                                                    onChange={(event) => {
                                                        setSelectedNode({
                                                            ...selectedNode,
                                                            data: {
                                                                ...selectedNode.data,
                                                                afterTimeUnit: event.target.value
                                                            }
                                                        });

                                                        setNodes(nodes.map(node => {
                                                            if (node.id !== selectedNode.id) {
                                                                return node;
                                                            }

                                                            return {
                                                                ...node,
                                                                data: {
                                                                    ...node.data,
                                                                    afterTimeUnit: event.target.value
                                                                }
                                                            }
                                                        }));

                                                        const edge = edges.find((edge) => edge.target == selectedNode.id);
                                                        if (edge) {
                                                            setEdges(edges.map(edg => {
                                                                return {
                                                                    ...edg,
                                                                    label: `${selectedNode?.data?.afterTime ? selectedNode?.data?.afterTime : 2}${event.target.value}`
                                                                }
                                                            }))
                                                        }

                                                        if (!scenarioId) {
                                                            return;
                                                        }

                                                        dispatch(updateSeedingContent({
                                                            scenarioId: parseInt(scenarioId),
                                                            contentNodeId: parseInt(selectedNode.id),
                                                            content: {
                                                                after_time_unit: event.target.value as string
                                                            }
                                                        }));
                                                    }}
                                                >
                                                    <MenuItem value='second'>Giây</MenuItem>
                                                    <MenuItem value='month'>Phút</MenuItem>
                                                    <MenuItem value='hour'>Giờ</MenuItem>
                                                    <MenuItem value='day'>Ngày</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                    <Typography variant="h4">Nội dung bài đăng</Typography>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={editorData}
                                        onChange={(event, editor) => {
                                            setEditorData(editor.getData());

                                            if (!scenarioId) {
                                                return;
                                            }

                                            setSelectedNode({
                                                ...selectedNode,
                                                data: {
                                                    ...selectedNode.data,
                                                    content: editor.getData()
                                                }
                                            });
                                            setNodes(nodes.map(node => {
                                                if (node.id !== selectedNode.id) {
                                                    return node;
                                                }

                                                return {
                                                    ...node,
                                                    data: {
                                                        ...node.data,
                                                        content: editor.getData()
                                                    }
                                                }
                                            }));

                                            dispatch(updateSeedingContent({
                                                scenarioId: parseInt(scenarioId),
                                                contentNodeId: parseInt(selectedNode.id),
                                                content: {
                                                    content: editor.getData()
                                                }
                                            }))
                                        }}
                                        config={{
                                            toolbar: [
                                                'undo', 'redo', '|',
                                                'heading', '|', 'bold', 'italic', '|',
                                                'link', 'insertTable', 'mediaEmbed', '|',
                                                'bulletedList', 'numberedList', 'indent', 'outdent'
                                            ],
                                            plugins: [
                                                Bold,
                                                Essentials,
                                                Heading,
                                                Indent,
                                                IndentBlock,
                                                Italic,
                                                Link,
                                                List,
                                                MediaEmbed,
                                                Paragraph,
                                                Table,
                                                Undo
                                            ]
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        :
                        <Grid container spacing={2}>
                            {
                                nodeOptions.map(nodeOption => (
                                    <Grid item xs={6}>
                                        <NodeTypeButton
                                            key={nodeOption.value}
                                            label={nodeOption.label}
                                            onClick={() => {

                                                if (!scenarioId) {
                                                    return;
                                                }

                                                setNodes((nds) => [
                                                    ...nds,
                                                    {
                                                        id: (lastNodeId + 1).toString(),
                                                        type: 'custom',
                                                        data: {
                                                            label: nodeOption.label,
                                                            content: '',
                                                            afterTime: '2',
                                                            afterTimeUnit: 'second',
                                                            onNodeHandleMouseUp: onNodeHandleMouseUp,
                                                            onNodeHandleMouseDown: onNodeHandleMouseDown
                                                        },
                                                        style: { background: 'white' },
                                                        position: {
                                                            x: lastNodePosition.x + 20,
                                                            y: lastNodePosition.y + 20
                                                        }
                                                    }
                                                ]);

                                                dispatch(createSeedingContent({
                                                    scenarioId: parseInt(scenarioId),
                                                    content: {
                                                        scenario_id: parseInt(scenarioId),
                                                        parent_id: null,
                                                        type: nodeOption.value,
                                                        content: null,
                                                        after_time: 2,
                                                        after_time_unit: 'second',
                                                        node_id: (lastNodeId + 1).toString(),
                                                        node_position_x: lastNodePosition.x,
                                                        node_position_y: lastNodePosition.y
                                                    }
                                                }));

                                                setLastNodePosition({
                                                    x: lastNodePosition.x + 20,
                                                    y: lastNodePosition.y + 20
                                                });
                                                setLastNodeId(lastNodeId + 1);
                                            }} />
                                    </Grid>
                                ))
                            }
                        </Grid>
                }
            </Paper >
        </>
    )
}

export default SeedingScenarioDetailPage;
import { ReactElement, useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { Box, Button, Checkbox, Chip, Container, Dialog, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppDispatch, useAppSelector } from "../../../hooks/redux.hook";
import { Account, Source, SourceFilter } from "../../../apis/account.api";
import { createBulkAccount, createChannelSource, getAccountPlatformList, getChannelSourceList, getSourceList, getSourcePlatformList } from "../../../stores/slices/account.slice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const AddSourceDialog: React.FC<{
    open: boolean,
    onClose: any,
    channelId: number | undefined,
    mediaType: string | undefined
}> = ({ open, onClose, channelId, mediaType }) => {
    const dispatch = useAppDispatch();

    const sources = useAppSelector((state) => state.account.sources);
    const channelSources = useAppSelector((state) => state.account.channelSources);
    const sourcePlatforms = useAppSelector((state) => state.account.sourcePlatforms);

    const [newSource, setNewSource] = useState<Source | undefined>();
    const [isAddingNewSource, setIsAddingNewSource] = useState<boolean>(false);
    const [sourceFilter, setSourceFilter] = useState<SourceFilter>({ platform: '', mediaType });

    useEffect(() => {
        if (channelId && mediaType) {
            dispatch(getSourcePlatformList()).unwrap().then((respSourcePlatforms) => setSourceFilter({
                ...sourceFilter,
                platform: respSourcePlatforms[0]
            }));
            dispatch(getChannelSourceList({
                channelId,
                mediaType
            }));
        }
    }, [channelId])

    return (
        <Dialog open={open} onClose={onClose} maxWidth='lg'>
            <Paper elevation={2} sx={{ padding: '10px' }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">Platform</StyledTableCell>
                            <StyledTableCell align="left">Name</StyledTableCell>
                            <StyledTableCell align="left">Category</StyledTableCell>
                            <StyledTableCell align="right">No Channel</StyledTableCell>
                            <StyledTableCell align="right">No Aff</StyledTableCell>
                            <StyledTableCell align="right">Published Aff</StyledTableCell>
                            <StyledTableCell align="right">No Item</StyledTableCell>
                            <StyledTableCell align="right">Published Item</StyledTableCell>
                            <StyledTableCell align="center">Is Today</StyledTableCell>
                            <StyledTableCell align="center">Is Old Post Available</StyledTableCell>
                            <StyledTableCell align="center">Is Old To New</StyledTableCell>
                            <StyledTableCell align="center">Is Active</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            channelSources.map(source => (
                                <StyledTableRow key={source.id}>
                                    <StyledTableCell align="left">{source.platform}</StyledTableCell>
                                    <StyledTableCell align="left"><a href={source.url} target="blank">{source.name}</a></StyledTableCell>
                                    <StyledTableCell align="left">Category</StyledTableCell>
                                    <StyledTableCell align="right">{source.no_channel}</StyledTableCell>
                                    <StyledTableCell align="right">{source.no_aff}</StyledTableCell>
                                    <StyledTableCell align="right">{source.no_published_aff}</StyledTableCell>
                                    <StyledTableCell align="right">{source.no_item}</StyledTableCell>
                                    <StyledTableCell align="right">{source.no_published_item}</StyledTableCell>
                                    <StyledTableCell align="center"><Checkbox checked={source.is_today} /></StyledTableCell>
                                    <StyledTableCell align="center"><Checkbox checked={source.is_old_post_available} /></StyledTableCell>
                                    <StyledTableCell align="center"><Checkbox checked={source.is_old_to_new} /></StyledTableCell>
                                    <StyledTableCell align="center"><Checkbox checked={source.is_active} /></StyledTableCell>
                                </StyledTableRow>
                            ))
                        }
                        {isAddingNewSource && (
                            <StyledTableRow>
                                <StyledTableCell align="left">
                                    <Select
                                        value={sourceFilter.platform}
                                        onChange={(e) => {

                                            dispatch(getSourceList({
                                                ...sourceFilter,
                                                platform: e.target.value
                                            })).unwrap().then((sourceList) => setNewSource({
                                                ...sourceList[0]
                                            }));

                                            setSourceFilter({
                                                ...sourceFilter,
                                                platform: e.target.value
                                            });
                                        }}
                                    >
                                        {sourcePlatforms.map(platform => <MenuItem key={platform} value={platform}>{platform}</MenuItem>)}
                                    </Select>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <Select
                                        fullWidth
                                        value={newSource?.id}
                                        onChange={(e) => {
                                            const source = sources.find(source => source.id == e.target.value);
                                            if (source) {
                                                setNewSource({...source})
                                            }
                                        }}
                                    >
                                        {sources.map(source => <MenuItem key={source.id} value={source.id}>{source.id}. {source.name}</MenuItem>)}
                                    </Select>
                                </StyledTableCell>
                                <StyledTableCell align="left">Category</StyledTableCell>
                                <StyledTableCell align="right">{newSource?.no_channel}</StyledTableCell>
                                <StyledTableCell align="right">{newSource?.no_aff}</StyledTableCell>
                                <StyledTableCell align="right">{newSource?.no_published_aff}</StyledTableCell>
                                <StyledTableCell align="right">{newSource?.no_item}</StyledTableCell>
                                <StyledTableCell align="right">{newSource?.no_published_item}</StyledTableCell>
                                <StyledTableCell align="center">
                                    {newSource && <Checkbox 
                                        checked={newSource?.is_today} 
                                        onClick={(e) => setNewSource({
                                            ...newSource,
                                            is_today: newSource?.is_today ? false : true
                                        })} 
                                    />}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {newSource && <Checkbox 
                                        checked={newSource?.is_old_post_available} 
                                        onClick={(e) => setNewSource({
                                            ...newSource,
                                            is_old_post_available: newSource?.is_old_post_available ? false : true
                                        })} 
                                    />}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {newSource && <Checkbox 
                                        checked={newSource?.is_old_to_new} 
                                        onClick={(e) => setNewSource({
                                            ...newSource,
                                            is_old_to_new: newSource?.is_old_to_new ? false : true
                                        })} 
                                    />}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {newSource && <Checkbox 
                                        checked={newSource?.is_active} 
                                        onClick={(e) => setNewSource({
                                            ...newSource,
                                            is_active: newSource?.is_active ? false : true
                                        })} 
                                    />}
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
                <Divider sx={{ margin: '30px' }} />
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 2,
                    margin: '0px 30px'
                }}>
                    {!isAddingNewSource && (
                        <Button
                            variant="contained"
                            onClick={async () => {
                                const sourceList = await dispatch(getSourceList(sourceFilter)).unwrap();
                                setNewSource({
                                    ...sourceList[0]
                                })

                                setIsAddingNewSource(true)
                            }}>
                            <AddIcon /> Source
                        </Button>
                    )}
                    {isAddingNewSource && (
                        <Button variant="contained" onClick={async () => {
                            if (
                                !newSource || 
                                !newSource.id || 
                                !channelId || 
                                !mediaType
                            ) {
                                return
                            }

                            await dispatch(createChannelSource({
                                channel_id: channelId,
                                source_id: newSource.id,
                                media_type: mediaType,
                                is_today: newSource.is_today ? true : false,
                                is_old_post_available: newSource.is_old_post_available ? true : false,
                                is_old_to_new: newSource.is_old_to_new ? true : false,
                                is_active: newSource.is_active ? true : false
                            }));

                            setNewSource(undefined);
                            setIsAddingNewSource(false);
                        }}>Save</Button>
                    )}
                    <Button variant="contained" color="error" onClick={() => {
                        setNewSource(undefined);
                        setIsAddingNewSource(false);
                        onClose();
                    }}>Close</Button>
                </Box>
            </Paper>
        </Dialog>
    )
}

export default AddSourceDialog;
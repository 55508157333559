import { APP_CONFIG } from "../config";
import { ApiListResp, ApiResp } from "../types/api-resp.type";
import { HttpApi } from "./http/http.api";
import { OrganizationEntity } from "./orgnaization.api";

export interface KolEntity {
    id: string;
    nickname: string;
    avatar: string;
    organization_id: string;
    no_streams?: number;

    organization?: OrganizationEntity;
    created_at?: string;
}

export interface KolFilter {
    organizationId?: string;
    sort_created_at?: 'asc' | 'desc'
}

export const getKolListApi = (filter: KolFilter): Promise<ApiListResp<KolEntity>> => {

    let _filter: {[key: string]: string} = {}
    if (filter.sort_created_at) {
        _filter['sort_created_at'] = filter.sort_created_at;
    }

    if (filter.organizationId) {
        _filter['organization_id'] = filter.organizationId;
    }
    
    const query = new URLSearchParams(_filter).toString();

    return HttpApi.get<ApiListResp<KolEntity>>(`${APP_CONFIG.API_URL}/kols?${query}`).then(({ data }) => data);
}
    

export const getKolByIdApi = (id: string): Promise<ApiResp<KolEntity>> =>
    HttpApi.get<ApiResp<KolEntity>>(`${APP_CONFIG.API_URL}/kols/${id}`).then(({ data }) => data);

export const createKolApi = (payload: KolEntity): Promise<ApiResp<KolEntity>> => 
    HttpApi.post<ApiResp<KolEntity>>(`${APP_CONFIG.API_URL}/kols`, payload).then(({ data }) => data);

export const updateKolByIdApi = (id: string, payload: KolEntity): Promise<ApiResp<KolEntity>> =>
    HttpApi.patch<ApiResp<KolEntity>>(`${APP_CONFIG.API_URL}/kols/${id}`, payload).then(({ data }) => data);

export const deleteKolByIdApi = (id: string): Promise<ApiResp<KolEntity>> =>
    HttpApi.delete<ApiResp<KolEntity>>(`${APP_CONFIG.API_URL}/kols/${id}`).then(({ data }) => data);
import { IconInfoSquare, IconNews } from '@tabler/icons-react';


const menuItems = {
    items: [
        {
            id: 'aff',
            title: 'AFF',
            type: 'group',
            children: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/',
                    icon: IconNews,
                    breadcrumbs: false
                },
                {
                    id: 'account',
                    title: 'Account',
                    type: 'item',
                    url: '/account',
                    icon: IconNews,
                    breadcrumbs: false
                },
                {
                    id: 'source',
                    title: 'Source',
                    type: 'item',
                    url: '/source',
                    icon: IconNews,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'seeding',
            title: 'Seeding',
            type: 'group',
            children: [
                {
                    id: 'scenario',
                    title: 'Scenario',
                    type: 'item',
                    url: '/seeding/scenario',
                    icon: IconNews,
                    breadcrumbs: false
                }
            ]
        },
    ]
}

export default menuItems;

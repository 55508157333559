import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Statistic, StatisticFilter, getStatisticApi } from "../../apis/statistic.api";

export interface StatisticSlice {
    statistic: Statistic | null;
}

const initialState: StatisticSlice = {
    statistic: null
};

export const getStatistic = createAsyncThunk('coupons/list', async (filter: StatisticFilter) => {
    const resp = await getStatisticApi(filter);
    return resp.result;
});

const statisticSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStatistic.fulfilled, (state, action) => {
        state.statistic = action.payload;
    });
  },
});

export default statisticSlice.reducer;
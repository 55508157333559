import dayjs, { Dayjs } from 'dayjs';
import { APP_CONFIG } from '../config';
import { ApiListResp, ApiResp } from '../types/api-resp.type';
import { HttpApi } from './http/http.api';
import { GameReward } from './game-reward.api';
import { KolEntity } from './kol.api';
import { OrganizationEntity } from './orgnaization.api';

export interface GameType {
    name: string;
    value: string;
}

export interface GameParticipantType {
    name: string;
    value: string;
}

export interface CreateGameData {
    title: string;
    banner_url?: string;
    game_type: string;
    game_organization_type: string;
    introduction: string | null;
    game_rule: string | null;
    rewards: GameReward[];
    start_time: string | null;
    end_time: string | null;
    participant_type: string;
    participant_number: number;
    members_per_team?: number | null;
}

export interface GameData extends CreateGameData {
    id: string | undefined,
    status: string,
    created_at: Date | undefined,
    modified_at: Date | undefined,
    deleted_at: Date | undefined

    created_by?: UserEntity
}

export interface UserRoleEntity {
    role_id: string;
    role_type: string;
}

export interface UserEntity {
    id: string;
    roles: UserRoleEntity[];
    fullname: string;
    phone: string;
    avatar_url: string;
    email: string;
    organization: OrganizationEntity;
}


export const createGameApi = (createGamePayload: CreateGameData): Promise<ApiResp<GameData>> =>
    HttpApi.post<ApiResp<GameData>>(`${APP_CONFIG.API_URL}/games`, { ...createGamePayload }).then(({ data }) => data);

export const getGameListApi = (): Promise<ApiListResp<GameData>> =>
    HttpApi.get<ApiListResp<GameData>>(`${APP_CONFIG.API_URL}/games`).then(({ data }) => data);

export const getGameTypesApi = (): Promise<ApiListResp<GameType>> =>
    HttpApi.get<ApiListResp<GameType>>(`${APP_CONFIG.API_URL}/games/types`).then(({ data }) => data);

export const getGameByIdApi = async (gameId: string): Promise<ApiResp<GameData>> => 
    HttpApi.get<ApiResp<GameData>>(`${APP_CONFIG.API_URL}/games/${gameId}`).then(({ data }) => data);

export const updateGameByIdApi = (gameId: string, payload: GameData): Promise<ApiResp<GameData>> =>
    HttpApi.patch<ApiResp<GameData>>(`${APP_CONFIG.API_URL}/games/${gameId}`, { ...payload }).then(({ data }) => data);

export const getGameParticipantTypesApi = (): Promise<ApiListResp<GameParticipantType>> =>
    HttpApi.get<ApiListResp<GameParticipantType>>(`${APP_CONFIG.API_URL}/games/participants/types`).then(({ data }) => data);

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginPage from '../pages/login/login.page';
import NewsFeedPage from '../pages/newsfeed.page';
import MainLayout from '../layouts/dashboard.layout';
import SupportRequestPage from '../pages/support.page';
import MediaSupportDetailPage from '../pages/media-support-detail.page';
import StreamDetailPage from '../pages/stream-detail.page';
import GameDetailPage from '../pages/game-detail.page';
import KolPage from '../pages/kol.page';
import MemberPage from '../pages/member.page';
import PersonalPage from '../pages/personal.page';
import OrganizationKolPage from '../pages/organization-kol-page.page';
import KolDetailPage from '../pages/kol-detail.page';
import HomePage from '../pages/homepage/home.page';
import UserLoginPage from '../pages/user-login/user-login.page';
import PersonalInfoPage from '../pages/personal-info/personal-info.page';
import MerchantMetricPage from '../pages/merchant/merchant-metric/merchant-metric.page';
import CouponListPage from '../pages/merchant/coupon-list/coupon-list.page';
import CouponDetailPage from '../pages/merchant/coupon-detail/coupon-detail.page';
import CouponPerformancePage from '../pages/merchant/coupon-performance/coupon-performance.page';
import PrivacyPage from '../pages/privacy.page';
import HomeLayout from '../layouts/home.layout';
import TermsOfServicePage from '../pages/terms-of-service.page';
import DataDeletionPage from '../pages/homepage/data-deletion.page';
import SeedingScenarioListPage from '../pages/seeding/scenario/scenario-list.page';
import SeedingScenarioDetailPage from '../pages/seeding/scenario/scenario-detail.page';
import AccountListPage from '../pages/account/account.page';
import DashboardPage from '../pages/dashboard/dashboard.page';
import SourceListPage from '../pages/source/source.page';

// routes

// ==============================|| ROUTING RENDER ||============================== //

export const AppRouter: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/* <Route path="/club-admin" element={<MainLayout />}>
                    <Route path="/club-admin/giai-dau">
                        <Route path=":gameId" element={<GameDetailPage />} />
                    </Route>
                    <Route index element={<NewsFeedPage />} />
                    <Route path='/club-admin/ho-tro-truyen-thong'>
                        <Route path='' element={<SupportRequestPage />} />
                        <Route path=":mediaSupportId" element={<MediaSupportDetailPage />} />
                        <Route path=":mediaSupportId/:componentId" element={<MediaSupportDetailPage />} />
                    </Route>
                    <Route path='/club-admin/stream'>
                        <Route path=":streamId" element={<StreamDetailPage />} />
                        <Route path=":streamId/:componentId" element={<StreamDetailPage />} />
                    </Route>
                    <Route path='/club-admin/bang-tin'>
                        <Route path='' element={<NewsFeedPage />} />
                        <Route path=":eventType" element={<NewsFeedPage />} />
                    </Route>
                    <Route path='/club-admin/cong-dong' element={<MemberPage />} />
                    <Route path='/club-admin/cong-dong/kols' element={<KolPage />} />
                    <Route path='/club-admin/cau-lac-bo' element={<PersonalPage />} />
                    <Route path='/club-admin/cau-lac-bo/quan-ly-kol'>
                        <Route path="" element={<OrganizationKolPage />} />
                        <Route path=":kolId" element={<KolDetailPage />} />
                    </Route>
                </Route>
                <Route path="/merchant" element={<MainLayout />}>
                    <Route path='' element={<MerchantMetricPage />} />
                    <Route path='/merchant/e-voucher'>
                        <Route path='' element={<CouponListPage />} />
                        <Route path=":couponId" element={<CouponDetailPage />} />
                    </Route>
                    <Route path='/merchant/e-voucher/performance' element={<CouponPerformancePage />} />
                    <Route path='/merchant/e-voucher/add' element={<CouponDetailPage />} />
                </Route> */}
                <Route path="" element={<MainLayout />}>
                    <Route path="" element={<DashboardPage />} />
                    <Route path="/account" element={<AccountListPage />} />
                    <Route path="/source" element={<SourceListPage />} />
                    <Route path="/seeding/scenario">
                        <Route path="" element={<SeedingScenarioListPage />} />
                        <Route path=":scenarioId" element={<SeedingScenarioDetailPage />} />
                    </Route>
                </Route>
                {/* <Route path="/qua-tang" element={<PersonalInfoPage />} />
                <Route path="/login">
                    <Route path=":loginType" element={<LoginPage />} />
                </Route> */}
                <Route path="/dang-nhap" element={<UserLoginPage />} />
            </Routes>
        </BrowserRouter>
    );
}
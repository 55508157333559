import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { deleteToken, deleteUser, persistToken, readToken } from "../../services/local-storgage.service";
import { FbLogin, LoginRequest, LoginResponse, UserProfile, UserRole, getUserProfileApi, getUserRolesApi, login, loginFacebookApi } from '../../apis/auth.api';
import { setUser } from './user.slice';
import { ApiResp } from '../../types/api-resp.type';

export interface AuthSlice {
    token: string | null;
    profile: UserProfile | null;
    roles: UserRole[];
}

const initialState: AuthSlice = {
    token: readToken(),
    profile: null,
    roles: []
};

export const doLogin = createAsyncThunk(`auth/doLogin`, async (loginPayload: LoginRequest, { dispatch }) =>
    login(loginPayload).then((resp: ApiResp<LoginResponse>) => {
        dispatch(setUser(resp.result.user));
        persistToken(resp.result.token);
        return resp.result;
    }),
);

export const doLogout = createAsyncThunk('auth/doLogout', (payload, { dispatch }) => {
    deleteToken();
    deleteUser();
    dispatch(setUser(null));
});

export const doFacebookLogin = createAsyncThunk(`auth/doFacebookLogin`, async (payload: FbLogin, { dispatch }) => {
    try {
        const resp = await loginFacebookApi(payload);

        await dispatch(setUser(resp.result.user));
        await persistToken(resp.result.token);

        return resp.result;
    }
    catch (e) {
        return null;
    }
});

export const getUserProfile = createAsyncThunk('auth/getProfile', async (payload, { dispatch }) => {
    const resp = await getUserProfileApi();

    return resp.result;
});

export const getUserRoles = createAsyncThunk('auth/getUserRoles', async (payload, { dispatch }) => {
    const resp = await getUserRolesApi();
    return resp.result.items;
});

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(doLogin.fulfilled, (state, action) => {
            state.token = action.payload.token;
            state.profile = action.payload.user;
        });
        builder.addCase(doLogout.fulfilled, (state) => {
            state.token = '';
            state.profile = null;
        });
        builder.addCase(getUserProfile.fulfilled, (state, action) => {
            state.profile = action.payload;
        });
        builder.addCase(doFacebookLogin.fulfilled, (state, action) => {
            if (action.payload) {
                state.token = action.payload.token;
                state.profile = action.payload.user;
            }
        });
        builder.addCase(getUserRoles.fulfilled, (state, action) => {
            state.roles = action.payload;
        });
    },
});

export default authSlice.reducer;
import { APP_CONFIG } from '../config';
import { ApiListResp, ApiResp } from '../types/api-resp.type';
import { UserEntity } from './game.api';
import { HttpApi } from './http/http.api';
import { KolEntity } from './kol.api';


export interface OrganizationEntity {
    id: string;
    name: string;
    avatar_url: string;
    full_name: string;
    joined_at: string;
    no_kols?: number;
    no_created_events?: number;
    no_supported_events?: number;
    no_supported_streams?: number;
    no_supported_media_support?: number;
    kols?: KolEntity[];

    created_at?: string;
    modified_at?: string;
    deleted_at?: string

    created_by?: UserEntity
}

export const getOrganizationListApi = (): Promise<ApiListResp<OrganizationEntity>> =>
    HttpApi.get<ApiListResp<OrganizationEntity>>(`${APP_CONFIG.API_URL}/organizations`).then(({ data }) => data);

export const getOrganizationByIdApi = (organizationId: string): Promise<ApiResp<OrganizationEntity>> =>
    HttpApi.get<ApiResp<OrganizationEntity>>(`${APP_CONFIG.API_URL}/organizations/${organizationId}`).then(({ data }) => data);
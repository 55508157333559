import { ReactElement, useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { Box, Button, Chip, Container, Dialog, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppDispatch, useAppSelector } from "../../../hooks/redux.hook";
import { Account } from "../../../apis/account.api";
import { createBulkAccount, getAccountPlatformList, getChannelSourceCrawlerLog } from "../../../stores/slices/account.slice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const ChannelSourceCrawlerLogDialog: React.FC<{
    open: boolean,
    onClose: any,
    channelId: number | undefined,
    mediaType: string | undefined
}> = ({ open, onClose, channelId, mediaType }) => {
    const dispatch = useAppDispatch();

    const channelSourceCrawlerLog = useAppSelector((state) => state.account.channelSourceCrawlerLog);

    useEffect(() => {
        if (channelId && mediaType) {
            dispatch(getChannelSourceCrawlerLog({
                channelId,
                mediaType
            }));
        }
    }, [channelId, mediaType]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth='lg'>
            <Paper elevation={2} sx={{ padding: '10px' }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">Platform</StyledTableCell>
                            <StyledTableCell align="left">Name</StyledTableCell>
                            {
                                channelSourceCrawlerLog?.report && Object.keys(channelSourceCrawlerLog.report).map(
                                    reportDay => <StyledTableCell key={reportDay} align="right">{reportDay}</StyledTableCell>
                                )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            channelSourceCrawlerLog?.sources && channelSourceCrawlerLog.sources.map(source => (
                                <TableRow>
                                    <StyledTableCell align="left">{source.platform}</StyledTableCell>
                                    <StyledTableCell align="left">{source.name}</StyledTableCell>
                                    {
                                        channelSourceCrawlerLog?.report && Object.keys(channelSourceCrawlerLog.report).map(reportDay => (
                                            <StyledTableCell 
                                                key={source?.id} 
                                                align="right"
                                            >
                                                {source?.id && channelSourceCrawlerLog.report[reportDay][source.id]}
                                            </StyledTableCell>
                                        ))
                                    }
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 2
                }}>
                    <Button variant="contained" color="error" onClick={onClose}>Close</Button>
                </Box>
            </Paper>
        </Dialog>
    )
}

export default ChannelSourceCrawlerLogDialog;
import { useCallback } from 'react';
import { Handle, Position, type Node, type NodeProps } from '@xyflow/react';
import { Box } from '@mui/material';
import HtmlContent from '../../../../components/html-loader.component';
import styled from 'styled-components';

type CustomNodeData = Node<{ 
    label: string, 
    content: string
    afterTime: number;
    afterTimeUnit: string;
    onNodeHandleMouseUp: CallableFunction;
    onNodeHandleMouseDown: CallableFunction;
}, 'custom'>;


const StyledHtmlWrapper = styled.div`
    * {
        font-size: 7px;
        margin: 0px !important;
        line-height: 1.5;
    }
`;

const CustomNode = ({ 
    id,
    data
}: NodeProps<CustomNodeData> )=> {

    function truncateHtml(html: string, maxLength: number): string {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        let truncatedHtml = '';
        let currentLength = 0;

        function traverseNodes(node: ChildNode | null): boolean {
            if (!node || currentLength >= maxLength) {
                return false;
            }

            if (node.nodeType === Node.TEXT_NODE) {
                const text = node.textContent || '';
                const remainingLength = maxLength - currentLength;

                if (text.length > remainingLength) {
                    truncatedHtml += text.slice(0, remainingLength) + '...';
                    currentLength += remainingLength;
                    return false;
                } else {
                    truncatedHtml += text;
                    currentLength += text.length;
                }
            } else if (node.nodeType === Node.ELEMENT_NODE) {
                const element = node as HTMLElement;
                truncatedHtml += `<${element.tagName.toLowerCase()}`;

                // Add attributes if there are any
                for (const attr of Array.from(element.attributes)) {
                    truncatedHtml += ` ${attr.name}="${attr.value}"`;
                }
                truncatedHtml += '>';

                // Recursively process child nodes
                for (const child of Array.from(node.childNodes)) {
                    if (!traverseNodes(child)) {
                        return false;
                    }
                }

                truncatedHtml += `</${element.tagName.toLowerCase()}>`;
            }
            return true;
        }

        // Start traversing the DOM tree
        for (const child of Array.from(doc.body.childNodes)) {
            if (!traverseNodes(child)) break;
        }

        return truncatedHtml;
    }

    return (
        <Box sx={{
            padding: '10px 5px',
            border: '1px solid black',
            borderRadius: '3px',
            width: '150px'
        }}>
            <p style={{ fontSize: '12px', fontWeight: '500', margin: '0px', textAlign: 'center' }}>{data.label}</p>
            {
                data?.content && data.content.length 
                ? <StyledHtmlWrapper
                        dangerouslySetInnerHTML={{ __html: truncateHtml(data.content, 100) }}
                    /> 
                : ''
                    
            }
            
            <Handle id="right-source-handle" type="source" position={Position.Right} onMouseDown={() => data.onNodeHandleMouseDown(id)} onMouseUp={() => data.onNodeHandleMouseUp(id)} />
            <Handle id="right-target-handle" type="target" position={Position.Right} onMouseDown={() => data.onNodeHandleMouseDown(id)} onMouseUp={() => data.onNodeHandleMouseUp(id)} />

            <Handle id="left-source-handle" type="source" position={Position.Left} onMouseDown={() => data.onNodeHandleMouseDown(id)} onMouseUp={() => data.onNodeHandleMouseUp(id)} />
            <Handle id="left-target-handle" type="target" position={Position.Left} onMouseDown={() => data.onNodeHandleMouseDown(id)} onMouseUp={() => data.onNodeHandleMouseUp(id)} />

            <Handle id="top-source-handle" type="source" position={Position.Top} onMouseDown={() => data.onNodeHandleMouseDown(id)} onMouseUp={() => data.onNodeHandleMouseUp(id)} />
            <Handle id="top-target-handle" type="target" position={Position.Top} onMouseDown={() => data.onNodeHandleMouseDown(id)} onMouseUp={() => data.onNodeHandleMouseUp(id)} />

            <Handle id="bottom-source-handle" type="source" position={Position.Bottom} onMouseDown={() => data.onNodeHandleMouseDown(id)} onMouseUp={() => data.onNodeHandleMouseUp(id)} />
            <Handle id="bottom-target-handle" type="target" position={Position.Bottom} onMouseDown={() => data.onNodeHandleMouseDown(id)} onMouseUp={() => data.onNodeHandleMouseUp(id)} />
        </Box>

    )
}

export default CustomNode;
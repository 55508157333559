import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux.hook";
import { Box, Button, Chip, Container, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { getGameById, updateGameById } from "../../../stores/slices/game.slice";

import dayjs from "dayjs";
import { createSeedingScenario, getSeedingScenarioList } from "../../../stores/slices/seeding-scenario.slice";

interface ScenarioFormData {
    name: string;
    description: string;
}

const SeedingScenarioListPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const seedingScenarios = useAppSelector((state) => state.seedingScenario.items);
    const [isFormOpened, setIsFormOpened] = useState<boolean>(false);
    const [scenario, setScenario] = useState<ScenarioFormData>({
        name: '',
        description: ''
    })

    useEffect(() => {
        dispatch(getSeedingScenarioList());
    }, []);

    return (
        <Paper elevation={2} sx={{ padding: '20px' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ textAlign: 'right' }}>
                    <Button 
                        variant="contained"
                        onClick={() => setIsFormOpened(true)}
                    >
                        Create
                    </Button>
                </Grid>
                <Grid item xs={isFormOpened ? 7 : 12}>
                    <Box sx={{padding: '20px', border: '1px solid #cccccc', borderRadius: '3px'}}>
                        <Table>
                            <TableBody>
                                {seedingScenarios.map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell
                                            onClick={() => navigate(`/seeding/scenario/${item.id}`)}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <Typography variant="h3">{item.name}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">{item.description}</Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Grid>
                <Grid item xs={5} sx={{display: isFormOpened ? 'block' : 'none'}}>
                    <Box sx={{
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        border: '1px solid #cccccc',
                        borderRadius: '3px'
                    }}>
                        <TextField
                            label="Name"
                            variant="outlined"
                            value={scenario.name}
                            fullWidth
                            onChange={(event) => setScenario({
                                ...scenario,
                                name: event.target.value
                            })}
                        />
                        <TextField
                            label="Description"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            value={scenario.description}
                            onChange={(event) => setScenario({
                                ...scenario,
                                description: event.target.value
                            })}
                        />
                        <Box sx={{
                            textAlign: 'right',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <Button
                                variant="contained"
                                onClick={() => setIsFormOpened(false)}
                            >
                                Close
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    dispatch(createSeedingScenario(scenario))
                                }}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default SeedingScenarioListPage;
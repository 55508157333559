import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export interface NotificationMessage {
    content: string;
    type: 'error' | 'warning' | 'info' | 'success'
}

export interface NotificationSlice {
    message: NotificationMessage | null;
}

const initialState: NotificationSlice = {
    message: null,
}

export const notify = createAsyncThunk('notification/notify', (notification: NotificationMessage) => {
    return notification;
});

export const clearNotify = createAsyncThunk('notification/clearNotify', () => {
    return;
});

const notificationSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(notify.fulfilled, (state, action) => {
        state.message = action.payload;
    });

    builder.addCase(clearNotify.fulfilled, (state, action) => {
        state.message = null;
    });
  },
});

export default notificationSlice.reducer;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AffReport, AffReportFilter, getAffReportApi, Report } from "../../apis/report.api";

export interface ReportSlice {
    affReport: Report | null;
    latestDateClickReportImported: Date | null,
    latestDateCommissionReportImported: Date | null;
}

const initialState: ReportSlice = {
    affReport: null,
    latestDateClickReportImported: null,
    latestDateCommissionReportImported: null
};

export const getAffReport = createAsyncThunk('report/aff', async (filter: AffReportFilter) => {
    const resp = await getAffReportApi(filter);

    return resp.result;
});

const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAffReport.fulfilled, (state, action) => {
            state.affReport = action.payload.report;
            state.latestDateClickReportImported = new Date(action.payload.latest_date_click_report_imported);
            state.latestDateCommissionReportImported = new Date(action.payload.latest_date_commission_report_imported);
        });
    },
});

export default reportSlice.reducer;

import { createAsyncThunk,createSlice } from '@reduxjs/toolkit';
import { ApiResp } from '../../types/api-resp.type';
import { CreateRequestData, RequestData, RequestType, createRequest, getRequestList, getRequestTypes } from '../../apis/request.api';

export interface RequestSlice {
    localForm: CreateRequestData,
    requests: RequestData[],
    supportTypes: RequestType[],
    mediaSupportMethods: {name: string, value: string}[];
}

const initialState: RequestSlice = {
    localForm: {
        title: '',
        type: 'none',
        start_time: null,
        end_time: null,
        note: ''
    },
    requests: [],
    supportTypes: [],
    mediaSupportMethods: []
};

export const createSupportRequest = createAsyncThunk(`requests/create`, async (payload: CreateRequestData, { dispatch }) => {
    await createRequest(payload);
    dispatch(getSupportRequestList())
});

export const getSupportRequestList = createAsyncThunk('requests/list', async () => {
    const resp = await getRequestList();

    return resp.result.items
});

export const getSupportTypes = createAsyncThunk('requests/types', async () => {
    const resp = await getRequestTypes();
    const items = [];
    for (const key in resp.result) {
        items.push({
            name: key,
            value: resp.result[key]
        });
    }

    return items;
});


const requestSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createSupportRequest.fulfilled, (state, action) => {});
    builder.addCase(getSupportRequestList.fulfilled, (state, action) => {
        state.requests = action.payload
    });
    builder.addCase(getSupportTypes.fulfilled, (state, action) => {
        state.supportTypes = action.payload
    });
  },
});

export default requestSlice.reducer;
import { APP_CONFIG } from "../config";
import { ApiResp } from "../types/api-resp.type";
import { HttpApi } from "./http/http.api";

export interface TimeSeries {
    [key: string]: number;
}

export interface CouponStatistic {
    created?: TimeSeries[];
    used?: TimeSeries[];
}

export interface AwarenessStatistic {
    accessed?: TimeSeries[];
    viewed?: TimeSeries[];
    newAccessed?: TimeSeries[];
    leftInfo?: TimeSeries[];
}

export interface Statistic {
    coupon?: CouponStatistic;
    awareness?: AwarenessStatistic;
}

export interface StatisticFilter {
    merchant_id: string;
    from?: string;
    to?: string;
    fields?: keyof Statistic [];
}

export const getStatisticApi = async (payload: StatisticFilter): Promise<ApiResp<Statistic>> => 
    HttpApi.post<ApiResp<Statistic>>(`${APP_CONFIG.API_URL}/statistic`, payload).then(({ data }) => data);
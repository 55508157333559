import { ReactElement, useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { Box, Button, Chip, Container, Dialog, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppDispatch, useAppSelector } from "../../../hooks/redux.hook";
import { Account } from "../../../apis/account.api";
import { createBulkAccount, getAccountPlatformList } from "../../../stores/slices/account.slice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const EditableAccountItem: React.FC<{
    account: Account,
    accountPlatforms: string[],
    onChange: any
}> = ({ account, accountPlatforms, onChange }) => {

    const [accountItem, setAccountItem] = useState<Account>({...account});

    return (
        <StyledTableRow key={accountItem.id}>
            <StyledTableCell align="left">
                <Select
                    fullWidth
                    value={accountItem.platform}
                    label="Platform"
                    onChange={(e) => {
                        setAccountItem({...accountItem, platform: e.target.value});
                        onChange({...accountItem, platform: e.target.value});
                    }}
                >
                    {accountPlatforms.map(platform => <MenuItem key={platform} value={platform}>{platform}</MenuItem>)}
                </Select>
            </StyledTableCell>
            <StyledTableCell align="left">
                <TextField
                    fullWidth
                    variant="outlined"
                    value={accountItem.username}
                    onChange={(e) => {
                        setAccountItem({...accountItem, username: e.target.value});
                        onChange({...accountItem, password: e.target.value});
                    }} />
            </StyledTableCell>
            <StyledTableCell align="left">
                <TextField
                    fullWidth
                    variant="outlined"
                    value={accountItem.fullname}
                    onChange={(e) => {
                        setAccountItem({...accountItem, fullname: e.target.value});
                        onChange({...accountItem, fullname: e.target.value});
                    }} />
            </StyledTableCell>
            <StyledTableCell align="left">
                <TextField
                    fullWidth
                    variant="outlined"
                    type="password"
                    value={accountItem.password}
                    onChange={(e) => {
                        setAccountItem({...accountItem, password: e.target.value});
                        onChange({...accountItem, password: e.target.value});
                    }} />
            </StyledTableCell>
            <StyledTableCell align="center">
                <Button variant="contained" color="error" onClick={() => onChange(null)}><DeleteIcon /></Button>
            </StyledTableCell>
        </StyledTableRow>
    )
}


const CreateAccountDialog: React.FC<{
    open: boolean,
    onClose: any
}> = ({ open, onClose }) => {
    const dispatch = useAppDispatch();

    const accountPlatforms = useAppSelector((state) => state.account.accountPlatforms);

    const [accountId, setAccountId] = useState<number>(-1);
    const [accountMap, setAccountMap] = useState<{ [key: number]: Account }>({});

    useEffect(() => {
        dispatch(getAccountPlatformList());
    }, []);

    return (
        <Dialog open={open} onClose={onClose} maxWidth='lg'>
            <Paper elevation={2} sx={{ padding: '10px' }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">Platform</StyledTableCell>
                            <StyledTableCell align="left">Username</StyledTableCell>
                            <StyledTableCell align="left">Fullname</StyledTableCell>
                            <StyledTableCell align="left">Password</StyledTableCell>
                            <StyledTableCell align="left"></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Object.keys(accountMap).map(accountKey => <EditableAccountItem 
                                account={accountMap[parseInt(accountKey)]}
                                accountPlatforms={accountPlatforms}
                                onChange={(newAccount: Account | null) => {
                                    let newAccountMap: {[key: number]: Account} = {};
                                    if (!newAccount) {
                                        for (const k in accountMap) {
                                            if (k != accountKey) {
                                                newAccountMap[parseInt(k)] = accountMap[k];
                                            }
                                        }
                                    }
                                    else {
                                        newAccountMap = accountMap;
                                        newAccountMap[parseInt(accountKey)] = newAccount
                                    }

                                    setAccountMap(newAccountMap);
                                }}
                            />)
                        }
                        <StyledTableRow>
                            <StyledTableCell align="center" colSpan={5}>
                                <Button variant="contained" color="success" onClick={() => {
                                    const newAccountId = accountId - 1;

                                    let newAccountMap = accountMap;
                                    newAccountMap[newAccountId] = {
                                        id: newAccountId,
                                        platform: 'threads',
                                        username: '',
                                        fullname: '',
                                        password: ''
                                    }

                                    setAccountId(newAccountId);
                                    setAccountMap(newAccountMap);
                                }}><AddIcon />Add Account</Button>
                            </StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 2
                }}>
                    <Button variant="contained" color="error" onClick={() => {
                        setAccountId(-1);
                        setAccountMap({});
                        onClose();
                    }}>Close</Button>
                    <Button variant="contained" onClick={() => {
                        dispatch(createBulkAccount(Object.values(accountMap)));
                        setAccountId(-1);
                        setAccountMap({});
                        onClose();
                    }}>Save</Button>
                </Box>
            </Paper>
        </Dialog>
    )
}

export default CreateAccountDialog;
import userReducer from './slices/user.slice';
import authReducer from './slices/auth.slice';
import gameReducer from './slices/game.slice';
import eventReducer from './slices/event.slice';
import rewardProducer from './slices/game-reward.slice';
import requestReducer from './slices/request.slice';
import customizationReducer from './slices/customization.slice';
import mediaSupportReducer from './slices/media-support.slice';
import kolReducer from './slices/kol.slice';
import organizationReducer from './slices/organization.slice';
import couponReducer from './slices/coupon.slice';
import statisticReducer from './slices/statistic.slice';
import notificationReducer from './slices/notification.slice';
import seedingScenarioReducer from './slices/seeding-scenario.slice';
import accountReducer from './slices/account.slice';
import reportReducer from './slices/report.slice';
import sourceReducer from './slices/source.slice';

export default {
    kol: kolReducer,
    user: userReducer,
    auth: authReducer,
    request: requestReducer,
    game: gameReducer,
    event: eventReducer,
    mediaSupport: mediaSupportReducer,
    reward: rewardProducer,
    customization: customizationReducer,
    organization: organizationReducer,
    coupon: couponReducer,
    statistic: statisticReducer,
    notification: notificationReducer,
    seedingScenario: seedingScenarioReducer,
    account: accountReducer,
    report: reportReducer,
    source: sourceReducer
};

import colors from '../assets/scss/_themes-vars.module.scss';

export const customStyle = {
    customInput: {
        // marginTop: 1,
        // marginBottom: 1,
        // '& > label': {
        //     top: 23,
        //     left: 0,
        //     color: colors.grey500,
        //     '&[data-shrink="false"]': {
        //         top: 5
        //     }
        // },
        // '& > div > input': {
        //     padding: '30.5px 14px 11.5px !important'
        // },
        // '& legend': {
        //     display: 'none'
        // },
        // '& fieldset': {
        //     top: 0
        // }
    },
    mainContent: {
        backgroundColor: colors.primaryLight,
        width: '100%',
        minHeight: 'calc(100vh - 88px)',
        flexGrow: 1,
        // padding: '20px',
        paddingTop: '20px',
        marginTop: '88px',
        // marginRight: '20px',
        // borderRadius: `12px`
    },
    menuCaption: {
        fontSize: '0.875rem',
        fontWeight: 500,
        color: colors.grey900,
        padding: '6px',
        textTransform: 'capitalize',
        marginTop: '10px'
    },
    subMenuCaption: {
        fontSize: '0.6875rem',
        fontWeight: 500,
        color: colors.grey500,
        textTransform: 'capitalize'
    },
    commonAvatar: {
        cursor: 'pointer',
        borderRadius: '8px'
    },
    smallAvatar: {
        width: '22px',
        height: '22px',
        fontSize: '1rem'
    },
    mediumAvatar: {
        width: '34px',
        height: '34px',
        fontSize: '1.2rem'
    },
    largeAvatar: {
        width: '44px',
        height: '44px',
        fontSize: '1.5rem'
    },
    mediumTextButton: {
        height: '34px',
        padding: '7px 14px',
        fontSize: '1.5rem'
    }
}
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CreateGameReward, GameReward, RewardType, createGameRewardApi, deleteGameRewardByIdApi, getGameRewardByIdApi, getGameRewardListApi, getGameRewardTypesApi, updateGameRewardByIdApi } from '../../apis/game-reward.api';

export interface RewardSlice {
    rewards: GameReward[];
    rewardTypes: RewardType[];
    selectedReward: GameReward | null;
}

const initialState: RewardSlice = {
    rewards: [],
    rewardTypes: [],
    selectedReward: null
};

export const createReward = createAsyncThunk(`rewards/create`, async (
    payload: { gameId: string | undefined, reward: CreateGameReward },
    { dispatch, getState }
) => {
    if (payload.gameId) {
        const resp = await createGameRewardApi(payload.gameId, payload.reward);
        return resp.result;
    }
    else {
        const state = getState() as RewardSlice;
        await dispatch(createLocalReward({
            ...payload.reward,
            game_id: null,
            id: '',
            created_at: undefined,
            modified_at: undefined,
            deleted_at: undefined
        }))
    }
    dispatch(getRewardList(payload.gameId))
});

export const getRewardList = createAsyncThunk('rewards/list', async (gameId: string | undefined, { getState }) => {
    if (!gameId) {
        const state = getState() as RewardSlice;
        return state.rewards;
    }

    const resp = await getGameRewardListApi(gameId);
    return resp.result.items
});

export const getRewardById = createAsyncThunk('rewards/detail', async (rewardId: string) => {
    const resp = await getGameRewardByIdApi(rewardId);

    return resp.result;
});

export const getRewardTypes = createAsyncThunk('rewards/rewardTypes', async () => {
    const resp = await getGameRewardTypesApi();
    return resp.result.items;
});

export const updateRewardById = createAsyncThunk('rewards/update', async (payload: {rewardId: string, reward: GameReward}) => {
    const resp = await updateGameRewardByIdApi(payload.rewardId, payload.reward);

    return resp.result;
});

export const deleteRewardById = createAsyncThunk('rewards/delete', async (rewardId: string) => {
    const resp = await deleteGameRewardByIdApi(rewardId);

    return resp.result;
});

const createLocalReward = createAsyncThunk(`rewards/createLocal`, async (payload: GameReward, { getState }) => {
    const state = getState() as RewardSlice;
    const rewards = [];
    for (const reward of state.rewards) {
        rewards.push(reward);
    }

    return rewards;
})

const gameSlice = createSlice({
    name: 'rewards',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createReward.fulfilled, (state, action) => { });
        builder.addCase(getRewardList.fulfilled, (state, action) => {
            state.rewards = action.payload
        });
        builder.addCase(getRewardTypes.fulfilled, (state, action) => {
            state.rewardTypes = action.payload
        });
        builder.addCase(getRewardById.fulfilled, (state, action) => {
            state.selectedReward = action.payload
        });
        builder.addCase(createLocalReward.fulfilled, (state, action) => {
            state.rewards = action.payload;
        });
        builder.addCase(deleteRewardById.fulfilled, (state, action) => {});
        builder.addCase(updateRewardById.fulfilled, (state, action) => {
            state.selectedReward = action.payload
        });
    },
});

export default gameSlice.reducer;
import { useState } from "react";
import { styled } from '@mui/material/styles';
import { Box, Button, Dialog, Paper, Table, TableBody, TableCell, tableCellClasses, TableHead, TableRow, TextField } from "@mui/material";

import { useAppDispatch } from "../../../hooks/redux.hook";
import { Account } from "../../../apis/account.api";
import { updateAccount } from "../../../stores/slices/account.slice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const UpdateAccountDialog: React.FC<{
    account: Account,
    onClose: any
}> = ({ account, onClose }) => {
    const dispatch = useAppDispatch();

    const [editableAccount, setEditableAccount] = useState<Account>({ ...account })

    return (
        <Dialog open={account ? true : false} onClose={onClose} maxWidth='lg'>
            <Paper elevation={2} sx={{ padding: '10px' }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell align="left">Platform</StyledTableCell>
                            <StyledTableCell align="left">Username</StyledTableCell>
                            <StyledTableCell align="left">Fullname</StyledTableCell>
                            <StyledTableCell align="left">Password</StyledTableCell>
                            <StyledTableCell align="left"></StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                            <StyledTableCell align="left">{editableAccount?.platform}</StyledTableCell>
                            <StyledTableCell align="left">
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={editableAccount.username}
                                    onChange={(e) => setEditableAccount({
                                        ...editableAccount,
                                        username: e.target.value
                                    })} />
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={editableAccount.fullname}
                                    onChange={(e) => setEditableAccount({
                                        ...editableAccount,
                                        fullname: e.target.value
                                    })} />
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="password"
                                    value={editableAccount.password}
                                    onChange={(e) => setEditableAccount({
                                        ...editableAccount,
                                        password: e.target.value
                                    })} />
                            </StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 2
                }}>
                    <Button variant="contained" color="error" onClick={() => onClose()}>Close</Button>
                    <Button variant="contained" onClick={() => {
                        dispatch(updateAccount({...editableAccount}));
                        onClose();
                    }}>Save</Button>
                </Box>
            </Paper>
        </Dialog>
    )
}

export default UpdateAccountDialog;
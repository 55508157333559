import { MouseEvent, useState } from 'react';
import FacebookLogin from 'react-facebook-login';

// material-ui
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    Link,
    OutlinedInput,
    Stack,
    Typography
} from '@mui/material';

// project imports

// assets
import * as Yup from 'yup';
import useScriptRef from '../../hooks/use-script-ref.hook';
import { useAppDispatch } from '../../hooks/redux.hook';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnimateButton } from '../../components/button/animated-button.component';
import { APP_CONFIG } from '../../config';
import React from 'react';
import { doFacebookLogin, doLogin } from '../../stores/slices/auth.slice';
import { GoogleLogin } from '@react-oauth/google';
import { Formik } from 'formik';
import { customStyle } from '../../themes/style.them';

// ============================|| LOGIN ||============================ //


interface LoginFormData {
    username: string;
    password: string;
}

const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const LoginForm = ({ ...others }) => {
    const scriptedRef = useScriptRef();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const query = useQuery();

    const code = query.get('code');
    if (code) {
        dispatch(doFacebookLogin({
            code,
            redirect_url: APP_CONFIG.FB_REDIRECT_URL
        })).unwrap().then((loginResp) => {
            if (loginResp && loginResp.token) {
                navigate('/')
            }
        });
    }

    return (
        <>
            {/* <Grid container spacing={2}>
                <Grid item xs={12}>
                    <GoogleLogin
                        onSuccess={credentialResponse => {
                            console.log(credentialResponse);
                        }}
                        onError={() => {
                            console.log('Login Failed');
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ mt: 2 }}>
                        <AnimateButton>
                            <Button
                                disableElevation
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                href={`https://www.facebook.com/v17.0/dialog/oauth?client_id=146740125112085&redirect_uri=${APP_CONFIG.FB_REDIRECT_URL}&state=unicco`}
                            >
                                Login with Facebook
                            </Button>
                        </AnimateButton>
                    </Box>
                </Grid>
            </Grid> */}
            <Formik
                initialValues={{
                    username: '',
                    password: ''
                }}
                validationSchema={Yup.object().shape({
                    username: Yup.string().max(255).required('Hãy nhập Username'),
                    password: Yup.string().max(255).required('Hãy nhập Password')
                })}
                onSubmit={async (values: LoginFormData, { setErrors, setStatus, setSubmitting }) => {
                    setStatus({ success: true });
                    setSubmitting(false);

                    dispatch(doLogin({ ...values }))
                        .unwrap()
                        .then((loginResponse) => {
                            return navigate('/');
                        });
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setValues, submitForm }) => {
                    return (
                        <form noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth sx={{ ...customStyle.customInput }}>
                                        <InputLabel htmlFor="username">Username</InputLabel>
                                        <OutlinedInput
                                            id="username"
                                            type="text"
                                            value={values.username}
                                            name="username"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            label="Username"
                                        />
                                        {touched.username && errors.username && (
                                            <FormHelperText error id="standard-weight-helper-text-username-login">
                                                {errors.username}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth sx={{ ...customStyle.customInput }}>
                                        <InputLabel htmlFor="password">Password</InputLabel>
                                        <OutlinedInput
                                            id="password"
                                            type="password"
                                            value={values.password}
                                            name="password"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            label="Password"
                                        />
                                    </FormControl>
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                                        <Typography variant="subtitle1">
                                            Bạn chưa có tài khoản ?
                                        </Typography>
                                        <Link href="/dang-ky">Đăng ký</Link>
                                    </Stack>
                                </Grid> */}
                                <Grid item xs={12}>
                                    <Box sx={{ mt: 2 }}>
                                        <AnimateButton>
                                            <Button
                                                disableElevation
                                                disabled={isSubmitting}
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                color="secondary"
                                            >
                                                Đăng nhập
                                            </Button>
                                        </AnimateButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    )
                }}
            </Formik>
        </>
    );
};

export default LoginForm;

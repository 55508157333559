import { ReactElement, useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { Box, Button, Chip, Container, Dialog, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import { Account, AccountFilter, Channel, SourceFilter } from "../../apis/account.api";
import { getAccountList, getChannelPlatformList, getMediaTypeList, setFilter } from "../../stores/slices/account.slice";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import AddIcon from '@mui/icons-material/Add';
import { getAffReport } from "../../stores/slices/report.slice";
import { AffReportFilter } from "../../apis/report.api";
import { DatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { getSourcePlatforms, getSources } from "../../stores/slices/source.slice";
import CreateSourceDialog from "./dialog/create-source.dialog";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const SourceListPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const sources = useAppSelector((state) => state.source.items);
    const sourcePlatforms = useAppSelector((state) => state.source.platforms);

    const [filter, setFilter] = useState<SourceFilter>({
        platform: 'threads'
    });

    const [isCreateDialogOpened, setIsCreateDialogOpened] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getSourcePlatforms()).unwrap().then(platforms => {
            setFilter({
                platform: platforms[0]
            })
            dispatch(getSources({
                platform: platforms[0]
            }))
        })
        
    }, []);

    return (
        <>
            <Paper elevation={2} sx={{ padding: '10px', marginBottom: '20px' }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 2
                }}>
                    <FormControl>
                        <InputLabel>Platform</InputLabel>
                        <Select
                            value={filter.platform}
                            label="Platform"
                            onChange={(e) => {
                                const newFilter = {
                                    ...filter,
                                    platform: e.target.value
                                }
                                setFilter(newFilter);
                                dispatch(getSources(newFilter));
                            }}
                        >
                            {sourcePlatforms.map(platform => <MenuItem key={platform} value={platform}>{platform}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <Button variant="contained" onClick={() => setIsCreateDialogOpened(true)}><AddIcon /> Source</Button>
                </Box>
            </Paper>
            <Paper elevation={2} sx={{ padding: '10px', marginBottom: '20px' }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell>Platform</StyledTableCell>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell>No Channel</StyledTableCell>
                            <StyledTableCell>No Aff</StyledTableCell>
                            <StyledTableCell>No Post</StyledTableCell>
                            <StyledTableCell>No Video</StyledTableCell>
                            {
                                sources.length && sources[0]?.crawler_log && Object.keys(sources[0].crawler_log).map(
                                    period => <StyledTableCell>{period}</StyledTableCell>
                                )
                            }
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            sources.map(source => (
                                <StyledTableRow>
                                    <StyledTableCell>{source.platform}</StyledTableCell>
                                    <StyledTableCell sx={{background: source?.is_no_item_warning ? '#eccccf' : ''}}>{source.name}</StyledTableCell>
                                    <StyledTableCell>{source.no_channel}</StyledTableCell>
                                    <StyledTableCell>{source.no_aff}</StyledTableCell>
                                    <StyledTableCell>{source.no_post}</StyledTableCell>
                                    <StyledTableCell>{source.no_video}</StyledTableCell>
                                    {
                                        source?.crawler_log && Object.keys(source.crawler_log).map(
                                            period => <StyledTableCell>{source.crawler_log ? source.crawler_log[period] : 0}</StyledTableCell>
                                        )
                                    }
                                </StyledTableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </Paper>

            <CreateSourceDialog open={isCreateDialogOpened} onClose={() => setIsCreateDialogOpened(false)} />
        </>
    )
}

export default SourceListPage;
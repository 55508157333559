export const APP_CONFIG = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    BASENAME: '/',
    DEFAULT_PATH: '/',
    FONT_FAMILY: `'Roboto', sans-serif`,
    BORDER_RADIUS: 12,
    POST_PER_PAGE: 10,
    TRACKING_ID: 'G-E7T1RNCFP9',

    API_URL: 'http://localhost:10000', // local server
    // API_URL: 'http://localhost:10000',

    AUTO_TOOL_API_URL: 'https://fb.nguyenlethanhkhoa.com',
    // AUTO_TOOL_API_URL: 'http://localhost:12000',

    GG_OAUTH_CLIENT_ID: '606168179421-k8pr3epdpvqe13iha9kadt1g2ukvucc1.apps.googleusercontent.com',

    FB_REDIRECT_URL: 'https://unicco.vn/dang-nhap'
};

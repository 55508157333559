import { ReactElement, useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { Box, Button, Chip, Container, Dialog, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppDispatch, useAppSelector } from "../../../hooks/redux.hook";
import { Account } from "../../../apis/account.api";
import { createBulkAccount, getAccountPlatformList, getChannelSourceCrawlerLog, getChannelUploaderLog } from "../../../stores/slices/account.slice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const ChannelUploaderLogDialog: React.FC<{
    open: boolean,
    onClose: any,
    channelId: number | undefined,
    mediaType: string | undefined
}> = ({ open, onClose, channelId, mediaType }) => {
    const dispatch = useAppDispatch();

    const channelUploaderLog = useAppSelector((state) => state.account.channelUploaderLog);

    useEffect(() => {
        if (channelId && mediaType) {
            dispatch(getChannelUploaderLog({
                channelId,
                mediaType
            }));
        }
    }, [channelId, mediaType]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth='lg'>
            <Paper elevation={2} sx={{ padding: '10px' }}>
                <Box sx={{
                    display: 'flex'
                }}>
                    <Box sx={{
                        overflowY: 'auto'
                    }}>
                        <Table aria-label="customized table">
                            <TableBody>
                                {
                                    channelUploaderLog?.log_items.map(logItem => (
                                        <TableRow>
                                            <StyledTableCell align="left">{logItem.exec_time}</StyledTableCell>
                                            <StyledTableCell align="right">{logItem.item_id}</StyledTableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </Box>
                    <Box>
                        <Table aria-label="customized table">
                            <TableBody>
                                <TableRow>
                                    <StyledTableCell align="left" colSpan={2}>No Success</StyledTableCell>
                                    <StyledTableCell align="right" colSpan={2}>{channelUploaderLog?.summary.no_success}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell align="left" colSpan={2}>No Upload Failed</StyledTableCell>
                                    <StyledTableCell align="right" colSpan={2}>{channelUploaderLog?.summary.no_upload_failed}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell align="left" colSpan={2}>No Upload Aff Failed</StyledTableCell>
                                    <StyledTableCell align="right" colSpan={2}>{channelUploaderLog?.summary.no_upload_aff_failed}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell align="left" colSpan={2}>In Queue</StyledTableCell>
                                    <StyledTableCell align="right" colSpan={2}>{channelUploaderLog?.summary.in_queue}</StyledTableCell>
                                </TableRow>
                                {
                                    channelUploaderLog?.summary.no_download_failed && Object.keys(channelUploaderLog.summary.no_download_failed).map((platform, idx) => {

                                        if (idx == 0) {
                                            return (
                                                <TableRow>
                                                    <StyledTableCell align="left" rowSpan={Object.keys(channelUploaderLog.summary.no_download_failed).length}>No Download Failed</StyledTableCell>
                                                    <StyledTableCell align="left">{platform}</StyledTableCell>
                                                    <StyledTableCell align="right">{channelUploaderLog.summary.no_download_failed[platform]}</StyledTableCell>
                                                </TableRow>
                                            )
                                        }
                                        else {
                                            return (
                                                <TableRow>
                                                    <StyledTableCell align="left">{platform}</StyledTableCell>
                                                    <StyledTableCell align="right">{channelUploaderLog.summary.no_download_failed[platform]}</StyledTableCell>
                                                </TableRow>
                                            )
                                        }

                                    })
                                }
                            </TableBody>
                        </Table>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 2
                }}>
                    <Button variant="contained" color="error" onClick={onClose}>Close</Button>
                </Box>
            </Paper>
        </Dialog>
    )
}

export default ChannelUploaderLogDialog;
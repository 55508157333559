import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AffReport, AffReportFilter, getAffReportApi } from "../../apis/report.api";
import { Source, SourceFilter } from "../../apis/account.api";
import { createBulkSourceApi, getSourcePlatformsApi, getSourcesApi } from "../../apis/source.api";

export interface SourceSlice {
    items: Source[];
    platforms: string[];
}

const initialState: SourceSlice = {
    items: [],
    platforms: []
};

export const getSourcePlatforms = createAsyncThunk('source/platform/list', async () => {
    const resp = await getSourcePlatformsApi();

    return resp.result.items;
});

export const getSources = createAsyncThunk('source/list', async (filter: SourceFilter) => {
    const resp = await getSourcesApi(filter);

    return resp.result.items;
});

export const createBulkSource = createAsyncThunk(`source/create-bulk`, async (sources: Source[], { dispatch, getState }) => {

    const state: any = await getState();
    await createBulkSourceApi(sources);
    dispatch(getSources(state.account.filter));
});

const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getSources.fulfilled, (state, action) => {
            state.items = action.payload
        });

        builder.addCase(getSourcePlatforms.fulfilled, (state, action) => {
            state.platforms = action.payload
        });

        builder.addCase(createBulkSource.fulfilled, (state, action) => {});
    },
});

export default reportSlice.reducer;

import { ReactElement, useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { Box, Button, Chip, Container, Dialog, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppDispatch, useAppSelector } from "../../../hooks/redux.hook";
import { Account, Source } from "../../../apis/account.api";
import { createBulkAccount, getAccountPlatformList, getSourcePlatformList } from "../../../stores/slices/account.slice";
import { createBulkSource } from "../../../stores/slices/source.slice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const EditableSourceItem: React.FC<{ 
    source: Source, 
    onChange: any,
    sourcePlatforms: string[]
}> = ({ source, onChange, sourcePlatforms }) => {

    const [sourceItem, setSourceItem] = useState<Source>({...source});

    return (
        <StyledTableRow key={source.id}>
            <StyledTableCell align="left">
                <Select
                    fullWidth
                    value={sourceItem.platform}
                    label="Platform"
                    onChange={(e) => {
                        setSourceItem({...sourceItem, platform: e.target.value});
                        onChange({...sourceItem, platform: e.target.value});
                    }}
                >
                    {sourcePlatforms.map(platform => <MenuItem key={platform} value={platform}>{platform}</MenuItem>)}
                </Select>
            </StyledTableCell>
            <StyledTableCell align="left">
                <TextField
                    fullWidth
                    variant="outlined"
                    value={sourceItem.name}
                    onChange={(e) => {
                        setSourceItem({...sourceItem, name: e.target.value});
                        onChange({...sourceItem, name: e.target.value});
                    }} />
            </StyledTableCell>
            <StyledTableCell align="left">
                <TextField
                    fullWidth
                    variant="outlined"
                    value={sourceItem.url}
                    onChange={(e) => {
                        setSourceItem({...sourceItem, url: e.target.value});
                        onChange({...sourceItem, url: e.target.value});
                    }} />
            </StyledTableCell>
            <StyledTableCell align="center" width={50}>
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => onChange(null)}
                >
                    <DeleteIcon />
                </Button>
            </StyledTableCell>
        </StyledTableRow>
    )
}


const CreateSourceDialog: React.FC<{
    open: boolean,
    onClose: any
}> = ({ open, onClose }) => {
    const dispatch = useAppDispatch();

    const sourcePlatforms = useAppSelector((state) => state.source.platforms);

    const [sourceId, setSourceId] = useState<number>(-1);
    const [sourceMap, setSourceMap] = useState<{ [key: number]: Source }>({});

    useEffect(() => {
        dispatch(getSourcePlatformList());
    }, []);

    return (
        <Dialog open={open} onClose={onClose} maxWidth='lg'>
            <Paper elevation={2} sx={{ padding: '10px' }}>
                <Table sx={{ minWidth: 1100 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left" width={180}>Platform</StyledTableCell>
                            <StyledTableCell align="left" width={300}>Name</StyledTableCell>
                            <StyledTableCell align="left">Url</StyledTableCell>
                            <StyledTableCell align="left" width={70}></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Object.keys(sourceMap).map(sourceKey => <EditableSourceItem 
                                key={sourceKey}
                                source={sourceMap[parseInt(sourceKey)]} 
                                sourcePlatforms={sourcePlatforms} 
                                onChange={(newSource: Source | null) => {
                                    let newSourceMap: {[key: number]: Source} = {};
                                    if (!newSource) {
                                        for (const k in sourceMap) {
                                            if (k != sourceKey) {
                                                newSourceMap[parseInt(k)] = sourceMap[k];
                                            }
                                        }
                                    }
                                    else {
                                        newSourceMap = sourceMap;
                                        newSourceMap[parseInt(sourceKey)] = newSource
                                    }

                                    setSourceMap(newSourceMap);
                                }} />
                            )
                        }
                        <StyledTableRow>
                            <StyledTableCell align="left" colSpan={4} sx={{textAlign: 'center'}}>
                                <Button variant="contained" onClick={() => {
                                    const newSourceId = sourceId - 1;
                                    setSourceId(newSourceId);

                                    let newSourceMap = sourceMap;
                                    newSourceMap[newSourceId] = {
                                        id: newSourceId,
                                        url: '',
                                        name: '',
                                        platform: 'threads',
                                        is_active: true
                                    }

                                    setSourceMap(newSourceMap);
                                }}><AddIcon /> Source</Button>
                            </StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 2
                }}>
                    <Button variant="contained" color="error" onClick={() => {
                        setSourceMap({});
                        setSourceId(-1);
                        onClose();
                    }}>Close</Button>

                    <Button variant="contained" onClick={() => {
                        dispatch(createBulkSource(Object.values(sourceMap)));
                        setSourceId(-1);
                        setSourceMap({})
                        onClose();
                    }}>Save</Button>
                </Box>
            </Paper>
        </Dialog>
    )
}

export default CreateSourceDialog;
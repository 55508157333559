import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { OrganizationEntity, getOrganizationByIdApi, getOrganizationListApi } from "../../apis/orgnaization.api";

export interface OrganizationSlice {
    organizations: OrganizationEntity[],
    selectedOrganization: OrganizationEntity | null
}

const initialState: OrganizationSlice = {
    organizations: [],
    selectedOrganization: null
};

export const getOrganizationList = createAsyncThunk('organization/list', async () => {
    const resp = await getOrganizationListApi();
    
    return resp.result.items;
});

export const getOrganizationDetail = createAsyncThunk('organization/detail', async (organizationId: string) => {
    const resp = await getOrganizationByIdApi(organizationId);
    
    return resp.result;
});

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganizationList.fulfilled, (state, action) => {
        state.organizations = action.payload
    });
    builder.addCase(getOrganizationDetail.fulfilled, (state, action) => {
        state.selectedOrganization = action.payload
    });
  },
});

export default organizationSlice.reducer;
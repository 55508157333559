import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiResp } from '../../types/api-resp.type';
import { CreateRequestData, RequestData, RequestType, createRequest, getRequestList, getRequestTypes } from '../../apis/request.api';
import { createSeedingContentApi, createSeedingContentLinkApi, createSeedingScenarioApi, deleteSeedingContentApi, deleteSeedingContentLinkApi, getSeedingScenarioApi, getSeedingScenarioListApi, SeedingContent, SeedingContentLink, SeedingScenario, updateSeedingContentApi, UpdateSeedingContentPayload } from '../../apis/seeding-scenario.api';

export interface RequestSlice {
    items: SeedingScenario[],
    selectedItem: SeedingScenario | null
}

const initialState: RequestSlice = {
    items: [],
    selectedItem: null
};

export const createSeedingScenario = createAsyncThunk(`seeding/scenario/create`, async (payload: SeedingScenario, { dispatch }) => {
    await createSeedingScenarioApi(payload);
    dispatch(getSeedingScenarioList())
});

export const getSeedingScenarioList = createAsyncThunk('seeding/scenario/list', async () => {
    const resp = await getSeedingScenarioListApi();

    return resp.result.items;
});

export const getSeedingScenarioDetail = createAsyncThunk('seeding/scenario/detail', async (id: number) => {
    const resp = await getSeedingScenarioApi(id);

    return resp.result;
});

export const createSeedingContent = createAsyncThunk(
    `seeding/content/create`, 
    async (payload: {scenarioId: number, content: SeedingContent}, { dispatch }) => {
        await createSeedingContentApi(payload.scenarioId, payload.content);
    }
);

export const updateSeedingContent = createAsyncThunk(
    `seeding/content/update`, 
    async (payload: {scenarioId: number, contentNodeId: number, content: UpdateSeedingContentPayload}, { dispatch }) => {
        await updateSeedingContentApi(payload.scenarioId, payload.contentNodeId, payload.content);
    }
);

export const deleteSeedingContent = createAsyncThunk(
    `seeding/content/delete`, 
    async (payload: {scenarioId: number, contentNodeId: number}, { dispatch }) => {
        await deleteSeedingContentApi(payload.scenarioId, payload.contentNodeId);
    }
);

export const createSeedingContentLink = createAsyncThunk(
    `seeding/contentLink/create`, 
    async (payload: {scenarioId: number, contentLink: SeedingContentLink}, { dispatch }) => {
        await createSeedingContentLinkApi(payload.scenarioId, payload.contentLink);
    }
);

export const deleteSeedingContentLink = createAsyncThunk(
    `seeding/contentLink/delete`, 
    async (payload: {scenarioId: number, contentLinkId: string}, { dispatch }) => {
        await deleteSeedingContentLinkApi(payload.scenarioId, payload.contentLinkId);
    }
);



const seedingScenarioSlice = createSlice({
    name: 'seedingScenario',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createSeedingScenario.fulfilled, (state, action) => { });
        builder.addCase(getSeedingScenarioList.fulfilled, (state, action) => {
            state.items = action.payload
        });
        builder.addCase(getSeedingScenarioDetail.fulfilled, (state, action) => {
            state.selectedItem = action.payload
        });

        builder.addCase(createSeedingContent.fulfilled, (state, action) => { });
        builder.addCase(updateSeedingContent.fulfilled, (state, action) => { });
        builder.addCase(deleteSeedingContent.fulfilled, (state, action) => { });

        builder.addCase(createSeedingContentLink.fulfilled, (state, action) => { });
        builder.addCase(deleteSeedingContentLink.fulfilled, (state, action) => { });
    },
});

export default seedingScenarioSlice.reducer;
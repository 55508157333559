import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { ButtonBase, Typography } from '@mui/material';

// project imports
import Logo from './logo.component';
import { MENU_OPEN } from '../../stores/action.store';
import { APP_CONFIG } from '../../config';
import { openMenu } from '../../stores/slices/customization.slice';
import LogoSrc from "../../assets/img/logo.png";


// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    const defaultId = useSelector((state: any) => state.customization.defaultId);
    const dispatch = useDispatch();
    return (
        <ButtonBase disableRipple onClick={() => dispatch(openMenu(defaultId))} component={Link} to={APP_CONFIG.DEFAULT_PATH}>
            {/* <Logo /> */}
            <img style={{ width: '50px' }} src={LogoSrc} alt="Unicco" />
            <h1 style={{ marginLeft: "15px" }} className="font20 extraBold">
                unicco
            </h1>
        </ButtonBase>
    );
};

export default LogoSection;

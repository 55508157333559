import { APP_CONFIG } from "../config";
import { ApiListResp, ApiResp } from "../types/api-resp.type";
import { HttpApi } from "./http/http.api";

export interface RewardType {
    name: string;
    value: string;
}

export interface CreateGameReward {
    game_id: string | null | undefined;
    title: string;
    type: string;
    value_as_money: number;
    description: string;
}

export interface GameReward extends CreateGameReward {
    id: string;
    created_at: Date | undefined;
    modified_at: Date | undefined;
    deleted_at: Date | undefined;
}

export const createGameRewardApi = (gameId: string, createGamePayload: CreateGameReward): Promise<ApiResp<GameReward>> =>
    HttpApi.post<ApiResp<GameReward>>(`${APP_CONFIG.API_URL}/games/${gameId}/rewards`, { ...createGamePayload }).then(({ data }) => data);

export const getGameRewardListApi = (gameId: string): Promise<ApiListResp<GameReward>> =>
    HttpApi.get<ApiListResp<GameReward>>(`${APP_CONFIG.API_URL}/games/${gameId}/rewards`).then(({ data }) => data);

export const getGameRewardTypesApi = (): Promise<ApiListResp<RewardType>> =>
    HttpApi.get<ApiListResp<RewardType>>(`${APP_CONFIG.API_URL}/games/rewards/types`).then(({ data }) => data);

export const getGameRewardByIdApi = async (rewardId: string): Promise<ApiResp<GameReward>> => 
    HttpApi.get<ApiResp<GameReward>>(`${APP_CONFIG.API_URL}/games/rewards/${rewardId}`).then(({ data }) => data);

export const updateGameRewardByIdApi = (rewardId: string, payload: GameReward): Promise<ApiResp<GameReward>> =>
    HttpApi.patch<ApiResp<GameReward>>(`${APP_CONFIG.API_URL}/games/rewards/${rewardId}`, { ...payload }).then(({ data }) => data);

export const deleteGameRewardByIdApi = (rewardId: string): Promise<ApiResp<null>> =>
    HttpApi.delete(`${APP_CONFIG.API_URL}/games/rewards/${rewardId}`).then(({ data }) => data);
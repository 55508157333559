import {
    BaseEdge,
    BezierEdge,
    Edge,
    EdgeLabelRenderer,
    EdgeProps,
    getBezierPath,
    getStraightPath,
    SmoothStepEdge,
    useReactFlow,
} from '@xyflow/react';

type CustomEdgeData = Edge<{ 
    afterTime: number, 
    afterTimeUnit: string 
}, 'custom'>;

// interface CustomEdgeProps extends EdgeProps {
//     data: CustomEdgeData
// }

const CustomEdge = ({
    id,
    sourceX, 
    sourceY, 
    targetX, 
    targetY,
    sourcePosition,
    targetPosition,
    data
}: EdgeProps<CustomEdgeData>) => {

    return (
        <>
            <BezierEdge 
                id={id} 
                sourceX={sourceX} 
                sourceY={sourceY} 
                targetX={targetX} 
                targetY={targetY} 
                sourcePosition={sourcePosition} 
                targetPosition={targetPosition} />
            {/* <EdgeLabelRenderer>
                <button
                    style={{
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        pointerEvents: 'all',
                    }}
                    className="nodrag nopan"
                >
                    {data?.afterTime} {data?.afterTimeUnit}
                </button>
            </EdgeLabelRenderer> */}
        </>
    );
}

export default CustomEdge;

import { ReactElement, useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { Box, Button, Chip, Container, Dialog, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import { Account, AccountFilter, Channel } from "../../apis/account.api";
import { getAccountList, getChannelPlatformList, getMediaTypeList, setFilter } from "../../stores/slices/account.slice";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import AddIcon from '@mui/icons-material/Add';
import CreateAccountDialog from "./dialog/create-account.dialog";
import AddSourceDialog from "./dialog/add-source.dialog";
import ChannelSourceCrawlerLogDialog from "./dialog/channel-source-crawler-log.dialog";
import ChannelUploaderLogDialog from "./dialog/channel-uploader-log.dialog";
import UpdateAccountDialog from "./dialog/update-account.dialog";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const AccountListPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const theme = useTheme();

    const filter = useAppSelector((state) => state.account.filter);
    const accounts = useAppSelector((state) => state.account.items);
    const mediaTypes = useAppSelector((state) => state.account.mediaTypes);
    const channelPlatforms = useAppSelector((state) => state.account.channelPlatforms);

    const [account, setAccount] = useState<Account>({
        platform: '',
        username: '',
        fullname: '',
        password: ''
    })

    const isXs = useMediaQuery(theme.breakpoints.down('sm')); // xs and sm
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));

    const [selectedChannelId, setSelectedChannelId] = useState<number | undefined>(undefined);
    const [isCreateAccountDialogOpen, setIsCreateAccountDialogOpen] = useState<boolean>(false);
    const [isAddSourceDialogOpened, setIsAddSourceDialogOpened] = useState<boolean>(false);
    const [isChannelDialogOpened, setIsChannelDialogOpened] = useState<boolean>(false);
    const [isChannelSourceCrawlerLogDialogOpened, setIsChannelSourceCrawlerLogDialogOpened] = useState<boolean>(false);
    const [isChannelUploaderLogDialogOpened, setIsChannelUploaderLogDialogOpened] = useState<boolean>(false);

    const [selectedAccount, setSelectedAccount] = useState<Account | undefined>(undefined);


    useEffect(() => {
        dispatch(getMediaTypeList());
        dispatch(getChannelPlatformList());
        dispatch(getAccountList(filter));
    }, []);

    const parseChannelMetadata = (channel: Channel) => {
        console.log(channel.id, channel.sources?.length)
        if (!channel.sources?.length) {
            return <>
                <StyledTableCell align="left" scope="row">{channel.id}. {channel.name}</StyledTableCell>
                <StyledTableCell align="left" scope="row" colSpan={
                    isXs || isSm ? 3 : 5
                }>--------</StyledTableCell>
                <StyledTableCell align="right" scope="row">
                    <Button variant="contained" onClick={() => {
                        setSelectedChannelId(channel.id);
                        setIsAddSourceDialogOpened(true);
                    }}><AddIcon /></Button>
                </StyledTableCell>
            </>
        }
        else {
            return <>
                <StyledTableCell align="left" scope="row">{channel.id}. {channel.name}</StyledTableCell>
                <StyledTableCell align="right" scope="row" sx={{
                    background: channel?.is_last_published_warning ? '#eccccf' : ''
                }}>
                    {channel?.last_published}
                </StyledTableCell>
                <StyledTableCell
                    align="right"
                    scope="row"
                    sx={{
                        cursor: 'pointer',
                        background: channel?.is_no_rest_item_warning ? '#eccccf' : ''
                    }}
                    onClick={() => {
                        setSelectedChannelId(channel.id);
                        setIsChannelSourceCrawlerLogDialogOpened(true);
                    }}
                >
                    {channel?.no_rest_item}
                </StyledTableCell>
                <StyledTableCell align="right" scope="row" sx={{
                    display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'table-cell'
                    }
                }}>{channel?.no_published_aff}</StyledTableCell>
                <StyledTableCell
                    align="right"
                    scope="row"
                    sx={{
                        background: channel?.is_no_rest_aff_warning ? '#eccccf' : ''
                    }}
                >
                    {channel?.no_rest_aff}
                </StyledTableCell>
                <StyledTableCell align="right" scope="row" sx={{
                    display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'table-cell'
                    }
                }}>
                    <Button onClick={() => {
                        setSelectedChannelId(channel.id);
                        setIsChannelUploaderLogDialogOpened(true);
                    }}>
                        {channel?.no_failed_today}
                    </Button>
                </StyledTableCell>
                <StyledTableCell align="right" scope="row">
                    <Button onClick={() => {
                        setSelectedChannelId(channel.id);
                        setIsAddSourceDialogOpened(true)
                    }}>
                        <strong>{channel?.sources?.length}</strong>
                    </Button>
                </StyledTableCell>
            </>
        }

    }

    return (
        <>
            <Paper elevation={2} sx={{ padding: '10px', marginBottom: '20px' }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 2
                }}>
                    <FormControl>
                        <InputLabel>Media Type</InputLabel>
                        <Select
                            value={filter.mediaType}
                            label="Media Type"
                            onChange={(e) => {
                                const newFilter = {
                                    ...filter,
                                    mediaType: e.target.value
                                }
                                dispatch(setFilter(newFilter));
                                dispatch(getAccountList(newFilter));
                            }}
                        >
                            {mediaTypes.map(mediaType => <MenuItem key={mediaType} value={mediaType}>{mediaType}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel>Platform</InputLabel>
                        <Select
                            value={filter.platform}
                            label="Platform"
                            onChange={(e) => {
                                const newFilter = {
                                    ...filter,
                                    platform: e.target.value
                                }
                                dispatch(setFilter(newFilter));
                                dispatch(getAccountList(newFilter));
                            }}
                        >
                            {channelPlatforms.map(platform => <MenuItem key={platform} value={platform}>{platform}</MenuItem>)}
                        </Select>
                    </FormControl>

                    <Button variant="contained" onClick={() => setIsCreateAccountDialogOpen(true)}>
                        <AddIcon /> Account
                    </Button>
                </Box>
            </Paper>
            <Paper elevation={2} sx={{ padding: '10px' }}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left" sx={{
                                display: {
                                    xs: 'none',
                                    sm: 'none',
                                    md: 'table-cell'
                                }
                            }}>STT</StyledTableCell>
                            <StyledTableCell align="left" sx={{
                                display: {
                                    xs: 'none',
                                    sm: 'none',
                                    md: 'table-cell'
                                }
                            }}>Email</StyledTableCell>
                            <StyledTableCell align="left">Channel</StyledTableCell>
                            <StyledTableCell align="left">Last Published</StyledTableCell>
                            <StyledTableCell align="left">No Rest Item</StyledTableCell>
                            <StyledTableCell align="left" sx={{
                                display: {
                                    xs: 'none',
                                    sm: 'none',
                                    md: 'table-cell'
                                }
                            }}>No Published Aff</StyledTableCell>
                            <StyledTableCell align="left">No Rest Aff</StyledTableCell>
                            <StyledTableCell align="left" sx={{
                                display: {
                                    xs: 'none',
                                    sm: 'none',
                                    md: 'table-cell'
                                }
                            }}>No Failed</StyledTableCell>
                            <StyledTableCell align="left">Source</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {accounts.map((account, account_idx) => {

                            if (!account.channels?.length) {
                                return (
                                    <StyledTableRow key={account.id}>
                                        <StyledTableCell align="right" scope="row" sx={{
                                            display: {
                                                xs: 'none',
                                                sm: 'none',
                                                md: 'table-cell'
                                            }
                                        }}>
                                            {account_idx + 1}
                                        </StyledTableCell>
                                        <StyledTableCell align="left" scope="row">
                                            <span onClick={() => setSelectedAccount(account)} style={{cursor: 'pointer'}}>
                                                {account.id}. {account.username}
                                            </span>
                                        </StyledTableCell>
                                        <StyledTableCell align="left" scope="row" colSpan={7}>
                                            <Button variant="contained" onClick={() => setIsChannelDialogOpened(true)}><AddIcon /></Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                            }

                            return account.channels.map((channel, idx) => {
                                if (idx == 0) {
                                    return (
                                        <StyledTableRow key={channel.id}>
                                            <StyledTableCell align="right" scope="row" rowSpan={account.channels?.length} sx={{
                                                display: {
                                                    xs: 'none',
                                                    sm: 'none',
                                                    md: 'table-cell'
                                                }
                                            }}>
                                                {account_idx + 1}
                                            </StyledTableCell>
                                            <StyledTableCell align="left" scope="row" rowSpan={account.channels?.length} sx={{
                                                display: {
                                                    xs: 'none',
                                                    sm: 'none',
                                                    md: 'table-cell'
                                                }
                                            }}>
                                                <span onClick={() => setSelectedAccount(account)} style={{cursor: 'pointer'}}>
                                                    {account.id}. {account.username}
                                                </span>
                                            </StyledTableCell>
                                            {parseChannelMetadata(channel)}
                                        </StyledTableRow>
                                    )
                                }
                                else {
                                    return (
                                        <StyledTableRow key={channel.id}>
                                            {parseChannelMetadata(channel)}
                                        </StyledTableRow>
                                    )
                                }
                            })
                        })}
                    </TableBody>
                </Table>
            </Paper>
            <CreateAccountDialog
                onClose={() => setIsCreateAccountDialogOpen(false)}
                open={isCreateAccountDialogOpen}
            />

            <ChannelUploaderLogDialog
                channelId={selectedChannelId}
                onClose={() => setIsChannelUploaderLogDialogOpened(false)}
                open={isChannelUploaderLogDialogOpened}
                mediaType={filter.mediaType}
            />

            <ChannelSourceCrawlerLogDialog
                channelId={selectedChannelId}
                onClose={() => setIsChannelSourceCrawlerLogDialogOpened(false)}
                open={isChannelSourceCrawlerLogDialogOpened}
                mediaType={filter.mediaType}
            />

            <AddSourceDialog
                onClose={() => setIsAddSourceDialogOpened(false)}
                open={isAddSourceDialogOpened}
                channelId={selectedChannelId}
                mediaType={filter.mediaType}
            />

            {
                selectedAccount && <UpdateAccountDialog
                    onClose={() => setSelectedAccount(undefined)}
                    account={selectedAccount}
                />
            }
        </>
    )
}

export default AccountListPage;
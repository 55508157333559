import { createAsyncThunk,createSlice } from '@reduxjs/toolkit';
import { CreateGameData, GameData, GameParticipantType, GameType, createGameApi, getGameByIdApi, getGameListApi, getGameParticipantTypesApi, getGameTypesApi, updateGameByIdApi } from '../../apis/game.api';

export interface GameSlice {
    games: GameData[];
    gameTypes: GameType[];
    selectedGame: GameData | null;
    participantTypes: GameParticipantType[];
}

const initialState: GameSlice = {
    games: [],
    gameTypes: [],
    selectedGame: null,
    participantTypes: []
};

export const createGame = createAsyncThunk(`games/create`, async (payload: CreateGameData, { dispatch, fulfillWithValue }) => {
    const resp = await createGameApi(payload);
    dispatch(getGameList())

    if (resp.result?.id) {
        dispatch(getGameById(resp.result.id));
    }

    return resp.result;
});

export const getGameList = createAsyncThunk('games/list', async () => {
    const resp = await getGameListApi();

    return resp.result.items
});

export const getGameById = createAsyncThunk('games/detail', async (gameId: string) => {

    const resp = await getGameByIdApi(gameId);
    return resp.result;
});

export const updateGameById = createAsyncThunk('games/update', async (payload: GameData, { dispatch }) => {
    if (payload?.id) {
        const resp = await updateGameByIdApi(payload.id, payload);
        return resp.result;
    }

    return null;
});

export const getGameTypes = createAsyncThunk('games/gameTypes', async () => {
    const resp = await getGameTypesApi();

    return resp.result.items;
});

export const getGameParticipantTypes = createAsyncThunk('games/participantTypes', async () => {
    const resp = await getGameParticipantTypesApi();

    return resp.result.items;
});

const gameSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createGame.fulfilled, (state, action) => {});
    builder.addCase(getGameList.fulfilled, (state, action) => {
        state.games = action.payload
    });
    builder.addCase(getGameTypes.fulfilled, (state, action) => {
        state.gameTypes = action.payload
    });
    builder.addCase(getGameById.fulfilled, (state, action) => {
        if (action.payload) {
            state.selectedGame = action.payload
        }
    });
    builder.addCase(updateGameById.fulfilled, (state, action) => {
        state.selectedGame = action.payload
    });
    builder.addCase(getGameParticipantTypes.fulfilled, (state, action) => {
        state.participantTypes = action.payload
    });
  },
});

export default gameSlice.reducer;
import { useSelector } from 'react-redux';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';

import { theme } from './themes/app.theme';
import { AppRouter } from './routes/app.route';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ReactGA from 'react-ga';
import { APP_CONFIG } from './config';
import { useEffect } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

ReactGA.initialize(APP_CONFIG.TRACKING_ID);


const App: React.FC = () => {
    const customization = useSelector((state: any) => state.customization); // TODO: Refactor type

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme(customization)}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <GoogleOAuthProvider clientId="606168179421-k8pr3epdpvqe13iha9kadt1g2ukvucc1.apps.googleusercontent.com">
                        <CssBaseline />
                        <AppRouter />
                    </GoogleOAuthProvider>
                </LocalizationProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;

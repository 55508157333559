import { Handle, Position, type Node, type NodeProps } from '@xyflow/react';
import { Box } from '@mui/material';

type StartNodeData = Node<{ 
    label: string;
    onNodeHandleMouseUp: CallableFunction;
    onNodeHandleMouseDown: CallableFunction;
}, 'start'>;


const StartNode = ({ id, data }: NodeProps<StartNodeData>) => {

    return (
        <Box sx={{
            padding: '10px 5px',
            background: '#60d360',
            border: '1px solid black',
            borderRadius: '3px',
            width: '150px',
            boxShadow: '#c7c7c7 2px 2px 7px 1px',
            color: 'white'
        }}>
            <p style={{ fontSize: '12px', fontWeight: '500', margin: '0px', textAlign: 'center' }}>Start</p>

            <Handle id="bottom-source-handle" type="source" position={Position.Bottom} onMouseDown={() => data.onNodeHandleMouseDown(id)} onMouseUp={() => data.onNodeHandleMouseUp(id)} />
        </Box>

    )
}

export default StartNode;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Account, AccountFilter, Channel, ChannelLog, ChannelSourceCrawlerLog, createBulkAccountApi, createChannelSourceApi, CreateChannelSourcePayload, getAccountListApi, getAccountPlatformListApi, getChannelLogApi, getChannelPlatformListApi, getChannelSourceCrawlerLogApi, getChannelSourceListApi, getSourceListApi, getSourcePlatformListApi, Source, updateAccountApi } from "../../apis/account.api";

export interface AccountSlice {
    items: Account[];
    selectedItem: Account | null;
    mediaTypes: string[];
    channelPlatforms: string[];
    accountPlatforms: string[];
    channelSources: Source[];
    sourcePlatforms: string[];
    sources: Source[];
    filter: AccountFilter;
    channelSourceCrawlerLog: ChannelSourceCrawlerLog | null;
    channelUploaderLog: ChannelLog | null;
}

const initialState: AccountSlice = {
    items: [],
    selectedItem: null,
    mediaTypes: [],
    channelPlatforms: [],
    accountPlatforms: [],
    channelSources: [],
    sourcePlatforms: [],
    sources: [],
    filter: {
        mediaType: 'post',
        platform: 'threads'
    },
    channelSourceCrawlerLog: null,
    channelUploaderLog: null
};

export const createBulkAccount = createAsyncThunk(`account/create-bulk`, async (payload: Account[], { dispatch, getState }) => {

    const state: any = await getState();
    await createBulkAccountApi(payload);
    dispatch(getAccountList(state.account.filter));
});

export const createChannelSource = createAsyncThunk(`account/channel/source/create`, async (payload: CreateChannelSourcePayload, { dispatch, getState }) => {
    const state: any = await getState();
    await createChannelSourceApi(payload);
    dispatch(getChannelSourceList({
        channelId: payload.channel_id,
        mediaType: payload.media_type
    }));
    dispatch(getAccountList(state.account.filter));
});

export const getAccountList = createAsyncThunk('account/list', async (filter: AccountFilter) => {
    const resp = await getAccountListApi(filter);

    return resp.result.items;
});

export const getMediaTypeList = createAsyncThunk('account/mediaType/list', async () => {
    // const resp = await getMdediaListApi(filter);

    return [
        'post',
        'video'
    ];
});

export const setFilter = createAsyncThunk('account/list/filter', async (filter: AccountFilter) => {
    return filter;
});

export const getChannelPlatformList = createAsyncThunk('account/channelPlatform/list', async () => {
    const resp = await getChannelPlatformListApi();

    return resp.result.items;
});

export const getAccountPlatformList = createAsyncThunk('account/accountPlatform/list', async () => {
    const resp = await getAccountPlatformListApi();

    return resp.result.items;
});

export const updateAccount = createAsyncThunk('account/update', async (account: Account) => {

    if (!account.id) {
        return
    }

    await updateAccountApi(account.id, account);
})

export const getSourcePlatformList = createAsyncThunk('account/sourcePlatform/list', async () => {
    const resp = await getSourcePlatformListApi();

    return resp.result.items;
});

export const getChannelSourceList = createAsyncThunk('account/channel/source/list', async (payload: {
    channelId: number,
    mediaType: string
}) => {
    const resp = await getChannelSourceListApi(payload.channelId, payload.mediaType);

    return resp.result.sources;
});

export const getSourceList = createAsyncThunk('account/source/list', async (filter: AccountFilter) => {
    const resp = await getSourceListApi(filter);

    return resp.result.items;
});

export const getChannelSourceCrawlerLog = createAsyncThunk('account/channel/source/crawlerLog', async (payload: {
    channelId: number,
    mediaType: string
}) => {
    console.log('AAAAAAAAAAAAA')
    const resp = await getChannelSourceCrawlerLogApi(payload.channelId, payload.mediaType);

    console.log(resp);

    return resp.result;
});

export const getChannelUploaderLog = createAsyncThunk('account/channel/log', async (payload: {
    channelId: number,
    mediaType: string
}) => {
    const resp = await getChannelLogApi(payload.channelId, payload.mediaType);

    return resp.result;
});

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createBulkAccount.fulfilled, (state, action) => { });
        
        builder.addCase(updateAccount.fulfilled, (state, action) => { });

        builder.addCase(getAccountList.fulfilled, (state, action) => {
            state.items = action.payload
        });
        builder.addCase(getMediaTypeList.fulfilled, (state, action) => {
            state.mediaTypes = action.payload
        });
        builder.addCase(getChannelPlatformList.fulfilled, (state, action) => {
            state.channelPlatforms = action.payload
        });
        builder.addCase(getSourcePlatformList.fulfilled, (state, action) => {
            state.sourcePlatforms = action.payload
        });
        builder.addCase(getAccountPlatformList.fulfilled, (state, action) => {
            state.accountPlatforms = action.payload
        });
        builder.addCase(getChannelSourceList.fulfilled, (state, action) => {
            state.channelSources = action.payload ? action.payload : []
        });
        builder.addCase(getSourceList.fulfilled, (state, action) => {
            state.sources = action.payload
        });
        builder.addCase(setFilter.fulfilled, (state, action) => {
            state.filter = action.payload
        });
        builder.addCase(getChannelSourceCrawlerLog.fulfilled, (state, action) => {
            console.log(action.payload);
            state.channelSourceCrawlerLog = action.payload
        });
        builder.addCase(getChannelUploaderLog.fulfilled, (state, action) => {
            state.channelUploaderLog = action.payload
        });
    },
});

export default accountSlice.reducer;

import { Dayjs } from 'dayjs';
import { APP_CONFIG } from '../config';
import { ApiListResp, ApiResp } from '../types/api-resp.type';
import { HttpApi } from './http/http.api';

export const SeedingContentLabel = {
    'post': 'Đăng bài',
    'comment': 'Comment',
    'reply': 'Reply Comment'
}

export interface SeedingScenario {
    id?: number;
    name: string;
    description: string;
    content?: SeedingContent[];
    content_links?: SeedingContentLink[];
}

export type SeedingContentType = 'post' | 'comment' | 'reply' | 'start';

export interface SeedingContent {
    id?: number;
    scenario_id: number;
    parent_id: number | null;
    type: SeedingContentType,
    content: string | null;
    after_time: number | null;
    after_time_unit: string | null;
    node_id: string
    node_position_x: number;
    node_position_y: number;
}

export interface UpdateSeedingContentPayload {
    parent_id?: number | null;
    content?: string | null;
    node_position_x?: number;
    node_position_y?: number;
    after_time?: number | null;
    after_time_unit?: string | null;
}

export interface SeedingContentLink {
    id?: number;
    scenario_id: number;
    
    edge_id: string;
    edge_type: string;
    source_id: number;
    target_id: number;
    animated: boolean;
    label: string | null;
    source_handle: string;
    target_handle: string;
}

export const createSeedingScenarioApi = (payload: SeedingScenario): Promise<ApiResp<SeedingScenario>> =>
    HttpApi.post<ApiResp<SeedingScenario>>(`${APP_CONFIG.API_URL}/seeding/scenarios`, { ...payload }).then(({ data }) => data);

export const getSeedingScenarioListApi = (): Promise<ApiListResp<SeedingScenario>> =>
    HttpApi.get<ApiListResp<SeedingScenario>>(`${APP_CONFIG.API_URL}/seeding/scenarios`).then(({ data }) => data);

export const getSeedingScenarioApi = (id: number): Promise<ApiResp<SeedingScenario>> =>
    HttpApi.get<ApiResp<SeedingScenario>>(`${APP_CONFIG.API_URL}/seeding/scenarios/${id}`).then(({ data }) => data);

export const createSeedingContentApi = (scenarioId: number, payload: SeedingContent): Promise<ApiResp<SeedingContent>> =>
    HttpApi.post<ApiResp<SeedingContent>>(`${APP_CONFIG.API_URL}/seeding/scenarios/${scenarioId}/content`, { ...payload }).then(({ data }) => data);

export const updateSeedingContentApi = (scenarioId: number, contentNodeId: number, payload: UpdateSeedingContentPayload): Promise<ApiResp<SeedingContent>> =>
    HttpApi.patch<ApiResp<SeedingContent>>(`${APP_CONFIG.API_URL}/seeding/scenarios/${scenarioId}/content/${contentNodeId}`, { ...payload }).then(({ data }) => data);

export const deleteSeedingContentApi = (scenarioId: number, contentNodeId: number): Promise<ApiResp<null>> =>
    HttpApi.delete<ApiResp<null>>(`${APP_CONFIG.API_URL}/seeding/scenarios/${scenarioId}/content/${contentNodeId}`).then(({ data }) => data);

export const createSeedingContentLinkApi = (scenarioId: number, payload: SeedingContentLink): Promise<ApiResp<SeedingContentLink>> =>
    HttpApi.post<ApiResp<SeedingContentLink>>(`${APP_CONFIG.API_URL}/seeding/scenarios/${scenarioId}/content_links`, { ...payload }).then(({ data }) => data);

export const deleteSeedingContentLinkApi = (scenarioId: number, contentLinkId: string): Promise<ApiResp<null>> =>
    HttpApi.delete<ApiResp<null>>(`${APP_CONFIG.API_URL}/seeding/scenarios/${scenarioId}/content_links/${contentLinkId}`).then(({ data }) => data);

import { createAsyncThunk,createSlice } from '@reduxjs/toolkit';
import { KolEntity, KolFilter, createKolApi, deleteKolByIdApi, getKolByIdApi, getKolListApi, updateKolByIdApi } from '../../apis/kol.api';

export interface KolSlice {
    kols: KolEntity[];
    selectedKol: KolEntity | null;
}

const initialState: KolSlice = {
    kols: [],
    selectedKol: null
};

export const getKolList = createAsyncThunk('kols/list', async (filters: KolFilter) => {
    const resp = await getKolListApi(filters);

    return resp.result.items
});

export const getKolById = createAsyncThunk('kols/selected', async (id: string) => {
    const resp = await getKolByIdApi(id);

    return resp.result;
});

export const createKol = createAsyncThunk('kols/create', async (kol: KolEntity, { dispatch }) => {
    const resp = await createKolApi(kol);
    dispatch(getKolList({}));

    return resp.result;
})

export const deleteKolById = createAsyncThunk('kols/delete', async (id: string) => {
    await deleteKolByIdApi(id);
})

export const updateKolById = createAsyncThunk('kols/update', async (payload: {kolId: string, kol: KolEntity}, { dispatch }) => {
    const resp = await updateKolByIdApi(payload.kolId, payload.kol);

    return resp.result;
})

const kolSlice = createSlice({
  name: 'kols',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getKolList.fulfilled, (state, action) => {
        state.kols = action.payload
    });
    builder.addCase(getKolById.fulfilled, (state, action) => {
        state.selectedKol = action.payload
    });
    builder.addCase(createKol.fulfilled, (state, action) => {
        state.selectedKol = action.payload
    });
  },
});

export default kolSlice.reducer;
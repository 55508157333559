import { APP_CONFIG } from "../config";
import { ApiResp } from "../types/api-resp.type";
import { Channel } from "./account.api";
import { HttpApi } from "./http/http.api";

export interface AffReportChannel {
    total_published: number;
    total_click: number;
    total_order: number;
    total_aff_value: number;
    order_on_click: number;
    medium_click_time_from_published: number;
    medium_order_time_from_click: number;
    channel: Channel;
}

export interface AffReportPlatform {
    total_published: number;
    total_click: number;
    total_order: number;
    total_aff_value: number;
    channels: AffReportChannel[]
}

export interface Report {
    [key: string]: AffReportPlatform
}

export interface AffReport {
    latest_date_click_report_imported: string,
    latest_date_commission_report_imported: string;
    report: Report
}

export interface AffReportFilter {
    startTime: Date;
    endTime: Date
}

export const getAffReportApi = (filter: AffReportFilter): Promise<ApiResp<AffReport>> => {

    const params = [
        `start_time=${filter.startTime.toISOString()}`,
        `end_time=${filter.endTime.toISOString()}`,
    ];
    
    return HttpApi.get<ApiResp<AffReport>>(`${APP_CONFIG.AUTO_TOOL_API_URL}/reports/aff${params.length ? '?' + params.join('&') : ''}`).then(({ data }) => data);
}
import { APP_CONFIG } from '../config';
import { ApiListResp, ApiResp } from '../types/api-resp.type';
import { GameData, UserEntity } from './game.api';
import { HttpApi } from './http/http.api';
import { LivestreamEntity } from './livestream.api';
import { MediaSupportEntity } from './media-support.api';


export interface EventSupporterEntity {
    id: string;
    event_id: string;
    supported_by: UserEntity;
}

export interface EventEntity {
    id: string,
    slug: string,
    event_type: string;
    event_id: string;
    extension_data: GameData | MediaSupportEntity | LivestreamEntity | null;
    supporters: EventSupporterEntity[];
}

export interface EventFilter {
    sort_created_at?: 'asc' | 'desc';
    sort_supported_by?: string;
    event_type?: string;
    search?: string;
    created_by?: string;
    exclude_created_by?: string;
}

export const getEventListApi = (filter: EventFilter): Promise<ApiListResp<EventEntity>> => {

    let _filter: {[key: string]: string} = {}
    if (filter.sort_created_at) {
        _filter['sort_created_at'] = filter.sort_created_at;
    }

    if (filter.event_type) {
        _filter['event_type'] = filter.event_type;
    }

    if (filter.created_by) {
        _filter['created_by'] = filter.created_by;
    }

    if (filter.search) {
        _filter['search'] = filter.search;
    }

    if (filter.sort_supported_by) {
        _filter['sort_supported_by'] = filter.sort_supported_by;
    }

    if (filter.exclude_created_by) {
        _filter['exclude_created_by'] = filter.exclude_created_by;
    }
    
    const query = new URLSearchParams(_filter).toString();

    return HttpApi.get<ApiListResp<EventEntity>>(`${APP_CONFIG.API_URL}/events?${query}`).then(({ data }) => data);
}
    

export const submitEventSupportApi = (eventId: string): Promise<ApiResp<any>> =>
    HttpApi.post<ApiResp<any>>(`${APP_CONFIG.API_URL}/events/${eventId}/supports`).then(({ data }) => data);

export const getEventByEventTypeAndEventIdApi = (eventType: string, eventId: string): Promise<ApiResp<EventEntity>> =>
    HttpApi.get<ApiResp<EventEntity>>(`${APP_CONFIG.API_URL}/events/${eventType}/${eventId}`).then(({ data }) => data);

import { createAsyncThunk,createSlice } from '@reduxjs/toolkit';
import { Coupon, CouponFilter, createCouponApi, getCouponByIdApi, getCouponListApi, updateCouponByIdApi } from '../../apis/coupon.api';

export interface CouponSlice {
    coupons: Coupon[],
    totalCoupon: number,
    selectedCoupon: Coupon | null;
}

const initialState: CouponSlice = {
    coupons: [],
    totalCoupon: 0,
    selectedCoupon: null
};

export const getCouponList = createAsyncThunk('coupons/list', async (filter: CouponFilter) => {
    const resp = await getCouponListApi(filter);
    return resp.result;
});

export const getCouponById = createAsyncThunk('coupons/selectedCoupon', async (couponId: string) => {
    const resp = await getCouponByIdApi(couponId);
    return resp.result;
});

export const createCoupon = createAsyncThunk('coupons/createCoupon', async (coupon: Coupon) => {
    const resp = await createCouponApi(coupon);
    return resp.result;
});

export const updateCouponById = createAsyncThunk('coupons/updateCoupon', async (payload: {couponId: string, coupon: Coupon}) => {
    const resp = await updateCouponByIdApi(payload.couponId, payload.coupon);
    return resp.result;
});

const couponSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCouponList.fulfilled, (state, action) => {
        state.coupons = action.payload.items;
        state.totalCoupon = action.payload.total;
    });
    builder.addCase(getCouponById.fulfilled, (state, action) => {
        state.selectedCoupon = action.payload;
    });
  },
});

export default couponSlice.reducer;
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

// material-ui
import { SxProps, styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CircularProgress, Container, CssBaseline, Fab, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import navigation from '../components/menu/menu-item.component';

// assets
import { IconChevronRight, IconPlus } from '@tabler/icons-react';
import Breadcrumbs from '../components/breadcrumb/breadcrumb.component';
import { drawerWidth } from '../stores/constant.store';
import { useAppDispatch, useAppSelector } from '../hooks/redux.hook';
import Header from '../components/header/header.component';
import Sidebar from '../components/sidebar/sidebar.component';
import { MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { setMenu } from '../stores/slices/customization.slice';
import { customStyle } from '../themes/style.them';
import { doLogout, getUserRoles } from '../stores/slices/auth.slice';
import { NotificationMessage } from '../stores/slices/notification.slice';
import React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { ApiErrorData, HttpApi } from '../apis/http/http.api';
import { AxiosError } from 'axios';
import { ApiError } from '../apis/http/error.api';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }: { theme: any, open: boolean }) => ({
    ...customStyle.mainContent,
    padding: '15px',
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create(
            'margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const DashboardLayout = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    // Handle left drawer
    const leftDrawerOpened = useSelector((state: any) => state.customization.opened);
    const notification = useAppSelector((state: any) => state.notification.message);

    const handleLeftDrawerToggle: MouseEventHandler<HTMLDivElement> = () => {
        dispatch(setMenu(!leftDrawerOpened));
    };

    const showNotification = (message: NotificationMessage) => {
        setTimeout(() => {

        })
    }

    return (
        <>
            {showNotification(notification)}
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                {/* header */}
                <AppBar
                    enableColorOnDark
                    position="fixed"
                    color="inherit"
                    elevation={0}
                    sx={{
                        bgcolor: theme.palette.background.default,
                        transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                    }}
                >
                    <Toolbar>
                        <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                    </Toolbar>
                </AppBar>

                {/* drawer */}
                <Sidebar drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
                {/* <Sidebar  /> */}

                {/* main content */}
                <Main theme={theme} open={leftDrawerOpened}>
                    <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                    <Outlet />
                </Main>
            </Box>
        </>
    );
};

export default DashboardLayout;

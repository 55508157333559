import Box from "@mui/material/Box/Box";
import Draggable from "react-draggable";

const NodeTypeButton: React.FC<{onClick: any, label: string}> = ({onClick, label}) => {
    return (
        <Draggable>
            <Box
                sx={{
                    padding: '15px',
                    border: '2px solid black',
                    borderRadius: '5px',
                    background: 'white',
                    textAlign: 'center',
                    fontSize: '18px',
                    fontWeight: '600',
                    cursor: 'pointer'
                }}
                onClick={onClick}
            >
                {label}
            </Box>
        </Draggable>
    )
}

export default NodeTypeButton;
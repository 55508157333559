import { APP_CONFIG } from '../config';
import { ApiListResp, ApiResp } from '../types/api-resp.type';
import { User } from '../types/user.type';
import { UserEntity } from './game.api';
import { HttpApi } from './http/http.api';

export interface AuthData {
    email: string;
    password: string;
}

export interface LoginRequest {
    username: string | null;
    password: string | null;
}

export interface LoginResponse {
    token: string;
    user: UserEntity;
}

export interface FbLogin {
    code: string;
    redirect_url: string;
}

export interface UserProfile extends UserEntity {
    submitted_event_ids?: string[];
    supported_event_ids?: string[];
}

export interface UserRole {
    id: string;
    user_id: string;
    role_id: string;
    role_type: string;
}

export const login = (loginPayload: LoginRequest): Promise<ApiResp<LoginResponse>> =>
    HttpApi.post<ApiResp<LoginResponse>>(`${APP_CONFIG.AUTO_TOOL_API_URL}/auth/login`, { ...loginPayload }).then(({ data }) => data);

export const getUserProfileApi = (): Promise<ApiResp<UserProfile>> =>
    HttpApi.get<ApiResp<UserProfile>>(`${APP_CONFIG.API_URL}/auth/me`).then(({ data }) => data);

export const loginFacebookApi = (payload: FbLogin): Promise<ApiResp<LoginResponse>> =>
    HttpApi.post<ApiResp<LoginResponse>>(`${APP_CONFIG.API_URL}/auth/facebook`, payload).then(({ data }) => data);

export const getUserRolesApi = (): Promise<ApiListResp<UserRole>> =>
    HttpApi.get<ApiListResp<UserRole>>(`${APP_CONFIG.API_URL}/auth/roles`).then(({ data }) => data);
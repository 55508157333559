import { Dayjs } from 'dayjs';
import { APP_CONFIG } from '../config';
import { ApiListResp, ApiResp } from '../types/api-resp.type';
import { HttpApi } from './http/http.api';
import { UserEntity } from './game.api';

export interface MediaSupportMethod {
    name: string,
    value: string
}

export interface CreateMediaSupportEntity {
    title: string;
    support_method: string;
    start_time: string;
    media_url: string;
}

export interface MediaSupportEntity extends CreateMediaSupportEntity {
    id: string,
    status?: string,
    created_at?: string | undefined,
    modified_at?: string | undefined,
    deleted_at?: string | undefined

    created_by?: UserEntity
}

export const createMediaSupportApi = (payload: CreateMediaSupportEntity): Promise<ApiResp<MediaSupportEntity>> =>
    HttpApi.post<ApiResp<MediaSupportEntity>>(`${APP_CONFIG.API_URL}/media_supports`, { ...payload }).then(({ data }) => data);

export const getMediaSupportApi = (): Promise<ApiListResp<MediaSupportEntity>> =>
    HttpApi.get<ApiListResp<MediaSupportEntity>>(`${APP_CONFIG.API_URL}/media_supports`).then(({ data }) => data);

export const getMediaSupportMethodsApi = (): Promise<ApiListResp<MediaSupportMethod>> =>
    HttpApi.get<ApiListResp<MediaSupportMethod>>(`${APP_CONFIG.API_URL}/media_supports/types`).then(({ data }) => data);

export const getMediaSupportByIdApi = (mediaSupportId: string): Promise<ApiResp<MediaSupportEntity>> =>
    HttpApi.get<ApiResp<MediaSupportEntity>>(`${APP_CONFIG.API_URL}/media_supports/${mediaSupportId}`).then(({ data }) => data);

export const updateMediaSupportByIdApi = (mediaSupportId: string, payload: MediaSupportEntity): Promise<ApiResp<MediaSupportEntity>> =>
    HttpApi.patch<ApiResp<MediaSupportEntity>>(`${APP_CONFIG.API_URL}/media_supports/${mediaSupportId}`, { ...payload }).then(({ data }) => data);
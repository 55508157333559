import axios from 'axios';
import { AxiosError } from 'axios';
import { ApiError } from './error.api';
import { readToken } from '../../services/local-storgage.service';
import { useAppDispatch } from '../../hooks/redux.hook';
import { useNavigate } from 'react-router-dom';
import { doLogout } from '../../stores/slices/auth.slice';

export const HttpApi = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

HttpApi.interceptors.request.use((config: any) => { // TODO: Refactor type
    config.headers = { ...config.headers, Authorization: `Bearer ${readToken()}` };

    return config;
});

HttpApi.interceptors.response.use(undefined, (error: AxiosError<{ message: string }>) => {

    if (error.response?.status == 401) {
        console.log(window.location.host);
        window.location.replace(window.location.host + '/dang-nhap');        
    }

    throw new ApiError<ApiErrorData>(error.response?.data?.message || error.message);
});

export interface ApiErrorData {
    status: string;
    message: string;
    result: null;
}

import { ReactElement, useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { Box, Button, Chip, Container, Dialog, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import { Account, AccountFilter, Channel } from "../../apis/account.api";
import { getAccountList, getChannelPlatformList, getMediaTypeList, setFilter } from "../../stores/slices/account.slice";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import AddIcon from '@mui/icons-material/Add';
import { getAffReport } from "../../stores/slices/report.slice";
import { AffReportFilter } from "../../apis/report.api";
import { DatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const DashboardPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const affReport = useAppSelector((state) => state.report.affReport);
    const latestDateClickReportImported = useAppSelector((state) => state.report.latestDateClickReportImported);
    const latestDateCommissionReportImported = useAppSelector((state) => state.report.latestDateCommissionReportImported);

    const currentDate = new Date()
    const [filter, setFilter] = useState<{startTime: Dayjs, endTime: Dayjs}>({
        startTime: dayjs(new Date(currentDate.getUTCFullYear(), currentDate.getMonth(), 1)),
        endTime: dayjs(new Date(currentDate.getUTCFullYear(), currentDate.getMonth() + 1, 0, 23, 59, 59))
    });
    const [selectedPlatform, setSelectedPlatform] = useState<string | undefined>();

    useEffect(() => {
        dispatch(getAffReport({
            startTime: filter.startTime.toDate(),
            endTime: filter.endTime.toDate()
        }))
    }, []);

    const parseDay = (seconds: number) => {
        return (seconds / (24 * 60 * 60)).toFixed(2)
    }

    const parseDate = (dateObj: Date | null): string => {
        if (!dateObj) {
            return '';
        }
        return `${dateObj.getDate()}/${dateObj.getMonth()}/${dateObj.getFullYear()}`;
    }

    return (
        <>
            <Paper elevation={2} sx={{ padding: '10px', marginBottom: '20px' }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 2
                }}>
                    <Typography><strong>Click</strong><br/>{parseDate(latestDateClickReportImported)}</Typography>
                    <Typography><strong>Commission</strong><br/>{parseDate(latestDateCommissionReportImported)}</Typography>
                    <MobileDatePicker
                        label="From"
                        value={filter.startTime}
                        onChange={(newValue) => setFilter({
                            ...filter,
                            startTime: newValue ? newValue : dayjs(new Date(currentDate.getUTCFullYear(), currentDate.getMonth(), 1))
                        })}
                    />
                    <MobileDatePicker
                        label="To"
                        value={filter.endTime}
                        onChange={(newValue) => setFilter({
                            ...filter,
                            endTime: newValue ? newValue : dayjs(new Date(currentDate.getUTCFullYear(), currentDate.getMonth() + 1, 0, 23, 59, 59))
                        })}
                    />
                    <Button variant="contained" onClick={() => {
                        dispatch(getAffReport({
                            startTime: filter.startTime.toDate(),
                            endTime: filter.endTime.toDate()
                        }))
                    }}>Apply</Button>
                </Box>
            </Paper>
            <Paper elevation={2} sx={{ padding: '10px', marginBottom: '20px' }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left"></StyledTableCell>
                            {affReport && Object.keys(affReport).map((platform) =>
                                <StyledTableCell align="right" onClick={() => { setSelectedPlatform(platform) }}>{platform}</StyledTableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                            <StyledTableCell align="left" scope="row">Published</StyledTableCell>
                            {affReport && Object.keys(affReport).map((platform) => (
                                <StyledTableCell align="right" scope="row">
                                    {new Intl.NumberFormat().format(affReport[platform].total_published)}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell align="left" scope="row">Click</StyledTableCell>
                            {affReport && Object.keys(affReport).map((platform) => (
                                <StyledTableCell align="right" scope="row">
                                    {new Intl.NumberFormat().format(affReport[platform].total_click)}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell align="left" scope="row">Order</StyledTableCell>
                            {affReport && Object.keys(affReport).map((platform) => (
                                <StyledTableCell align="right" scope="row">
                                    {new Intl.NumberFormat().format(affReport[platform].total_order)}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell align="left" scope="row">Value</StyledTableCell>
                            {affReport && Object.keys(affReport).map((platform) => (
                                <StyledTableCell align="right" scope="row">
                                    {new Intl.NumberFormat().format(Math.round(affReport[platform].total_aff_value))}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </Paper>
            {selectedPlatform && (
                <Paper elevation={2} sx={{ padding: '10px', marginBottom: '20px' }}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">Channel</StyledTableCell>
                                <StyledTableCell align="left">Published to Click</StyledTableCell>
                                <StyledTableCell align="left">Click to Order</StyledTableCell>
                                <StyledTableCell align="left">Published</StyledTableCell>
                                <StyledTableCell align="left">Click</StyledTableCell>
                                <StyledTableCell align="left">Order</StyledTableCell>
                                <StyledTableCell align="left">Order / Click</StyledTableCell>
                                <StyledTableCell align="left">Value</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                affReport && affReport[selectedPlatform].channels.map(channel => (
                                    <TableRow>
                                        <StyledTableCell align="left">{channel.channel.id}. {channel.channel.name}</StyledTableCell>
                                        <StyledTableCell align="left">{parseDay(channel.medium_click_time_from_published)}</StyledTableCell>
                                        <StyledTableCell align="left">{parseDay(channel.medium_order_time_from_click)}</StyledTableCell>
                                        <StyledTableCell align="left">{new Intl.NumberFormat().format(channel.total_published)}</StyledTableCell>
                                        <StyledTableCell align="left">{new Intl.NumberFormat().format(channel.total_click)}</StyledTableCell>
                                        <StyledTableCell align="left">{new Intl.NumberFormat().format(channel.total_order)}</StyledTableCell>
                                        <StyledTableCell align="left">{channel.order_on_click ? (channel.order_on_click * 100).toFixed(2) + '%' : null}</StyledTableCell>
                                        <StyledTableCell align="left">{new Intl.NumberFormat().format(Math.round(channel.total_aff_value))}</StyledTableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </Paper>
            )}
        </>
    )
}

export default DashboardPage;
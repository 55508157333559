import React from "react";
// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import LogoSrc from "../../assets/img/logo.png";
import styled from "styled-components";
import ProfileSection from "../../components/profile/profile.component";
import { Typography } from "@mui/material";
import { UserProfile } from "../../apis/auth.api";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import { useNavigate } from "react-router-dom";
import { doLogout } from "../../stores/slices/auth.slice";

const Sidebar: React.FC<{
  sidebarOpen: boolean,
  toggleSidebar: any,
  userProfile: UserProfile
  hasClubAdminRole: boolean
}> = ({ sidebarOpen, toggleSidebar, userProfile, hasClubAdminRole }) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await dispatch(doLogout());
    window.location.reload();
  };

  const accessToken = localStorage.getItem('accessToken');

  return (
    <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          <img style={{ width: '50px' }} src={LogoSrc} alt="Unicco" />
          <h1 className="whiteColor font20" style={{ marginLeft: "15px" }}>
            unicco
          </h1>
        </div>
        <CloseBtn onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer">
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle className="flexNullCenter flexColumn">
        {/* <li className="semiBold font15 pointer">
          <a
            href="/"
            style={{
              padding: "10px 30px 10px 0",
              color: "white"
            }}
          >
            Nhà tài trợ
          </a>
        </li>
        <li className="semiBold font15 pointer">
          <a
            href="/"
            style={{
              padding: "10px 30px 10px 0",
              color: "white"
            }}
          >
            Quản lý CLB
          </a>
        </li> */}

        {
          hasClubAdminRole ? (
            <li className="semiBold font15 pointer">
              <a
                href="/club-admin"
                style={{
                  padding: "10px 30px 10px 0",
                  color: "white"
                }}
              >
                Quản lý CLB
              </a>
            </li>
          ) : null
        }

        {
          accessToken
            ?
            (
              <>
                <li className="semiBold font15 pointer">
                  <Typography variant="body2" color='white'>{userProfile?.fullname}</Typography>
                </li>
                <li className="semiBold font15 pointer">
                  <Typography variant="body2" color='white'>{userProfile?.phone}</Typography>
                </li>
                <li className="semiBold font15 pointer" onClick={() => navigate('/qua-tang')}>
                  <Typography variant="body2" color='white'>Bạn đang có 0 hộp quà</Typography>
                </li>
                <li className="semiBold font15 pointer" onClick={handleLogout}>
                  <Typography variant="body2" color='white'>Đăng xuất</Typography>
                </li>
              </>
            )
            :
            <li className="semiBold font15 pointer">
              <a
                href="/dang-nhap"
                style={{
                  padding: "10px 30px 10px 0",
                  color: "white"
                }}
              >
                Đăng nhập
              </a>
            </li>
        }


      </UlStyle>
    </Wrapper>
  );
}

const Wrapper = styled.nav<{ sidebarOpen: boolean }>`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;

export default Sidebar;

import { APP_CONFIG } from "../config";
import { ApiListResp, ApiResp } from "../types/api-resp.type";
import { Source, SourceFilter } from "./account.api";
import { HttpApi } from "./http/http.api";

export const getSourcesApi = (filter: SourceFilter): Promise<ApiListResp<Source>> => {

    const params = [
        `platform=${filter.platform}`
    ];
    
    return HttpApi.get<ApiListResp<Source>>(`${APP_CONFIG.AUTO_TOOL_API_URL}/sources${params.length ? '?' + params.join('&') : ''}`).then(({ data }) => data);
}

export const getSourcePlatformsApi = (): Promise<ApiListResp<string>> => 
    HttpApi.get<ApiListResp<string>>(`${APP_CONFIG.AUTO_TOOL_API_URL}/sources/platforms`).then(({ data }) => data);

export const createBulkSourceApi = (sources: Source[]): Promise<ApiResp<null>> =>
    HttpApi.post<ApiResp<null>>(`${APP_CONFIG.AUTO_TOOL_API_URL}/sources`, { 'sources': sources }).then(({ data }) => data);

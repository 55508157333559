import React, { useEffect, useState } from "react";
// Components
// Assets
import { Link } from "react-scroll";
import LogoIcon from "../../assets/svg/Logo";
import BurgerIcon from "../../assets/svg/BurgerIcon";
import LogoSrc from "../../assets/img/logo.png";
import Sidebar from "./sidebar.component";
import Backdrop from "./back-drop.component";
import styled from "styled-components";
import ProfileSection from "../../components/profile/profile.component";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import { getUserProfile } from "../../stores/slices/auth.slice";
import { UserProfile } from "../../apis/auth.api";

const TopNavbar: React.FC = () => {
	const dispatch = useAppDispatch();

	const [y, setY] = useState(window.scrollY);
	const [sidebarOpen, toggleSidebar] = useState(false);
	const [hasClubAdminRole, setHasGroupAdminRole] = useState<boolean>(false);


	const currentUserProfile: UserProfile = useAppSelector((state: any) => state.auth.profile);

	useEffect(() => {
		window.addEventListener("scroll", () => setY(window.scrollY));
		return () => {
			window.removeEventListener("scroll", () => setY(window.scrollY));
		};
	}, [y]);

	useEffect(() => {
		dispatch(getUserProfile()).unwrap().then((profile: UserProfile) => {
			for (const role of profile.roles) {
				if (role.role_type == 'club-admin') {
					setHasGroupAdminRole(true);
					break;
				}
			}
		})
	}, [])

	const accessToken = localStorage.getItem('accessToken');

	return (
		<>
			<Sidebar
				sidebarOpen={sidebarOpen}
				toggleSidebar={toggleSidebar}
				userProfile={currentUserProfile}
				hasClubAdminRole={hasClubAdminRole}
			/>
			{sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
			<Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "60px" } : { height: "80px" }}>
				<NavInner className="container flexSpaceCenter">
					<Link className="pointer flexNullCenter" to="/" smooth={true}>
						<img style={{ width: '50px' }} src={LogoSrc} alt="Unicco" />
						<h1 style={{ marginLeft: "15px" }} className="font20 extraBold">
							unicco
						</h1>
					</Link>
					<BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
						<BurgerIcon />
					</BurderWrapper>
					{/* <UlWrapper className="flexNullCenter">
						<li className="semiBold font15 pointer">
							<Link activeClass="active" style={{ padding: "10px 15px" }} to="home" spy={true} smooth={true} offset={-80}>
								Home
							</Link>
						</li>
						<li className="semiBold font15 pointer">
							<Link activeClass="active" style={{ padding: "10px 15px" }} to="services" spy={true} smooth={true} offset={-80}>
								Services
							</Link>
						</li>
						<li className="semiBold font15 pointer">
							<Link activeClass="active" style={{ padding: "10px 15px" }} to="projects" spy={true} smooth={true} offset={-80}>
								Projects
							</Link>
						</li>
						<li className="semiBold font15 pointer">
							<Link activeClass="active" style={{ padding: "10px 15px" }} to="blog" spy={true} smooth={true} offset={-80}>
								Blog
							</Link>
						</li>
						<li className="semiBold font15 pointer">
							<Link activeClass="active" style={{ padding: "10px 15px" }} to="pricing" spy={true} smooth={true} offset={-80}>
								Pricing
							</Link>
						</li>
						<li className="semiBold font15 pointer">
							<Link activeClass="active" style={{ padding: "10px 15px" }} to="contact" spy={true} smooth={true} offset={-80}>
								Contact
							</Link>
						</li>
					</UlWrapper> */}
					<UlWrapperRight className="flexNullCenter">
						{/* <li className="semiBold font15 pointer">
								<a href="/merchant" style={{ padding: "10px 30px 10px 0" }}>
									Nhà tài trợ
								</a>
							</li> */}
						{
							hasClubAdminRole ? (
								<li className="semiBold font15 pointer">
									<a href="/club-admin" style={{ padding: "10px 30px 10px 0" }}>
										Quản lý CLB
									</a>
								</li>
							) : null
						}
						{/* {
							accessToken
								?
								<li className="semiBold font15 pointer" style={{ margin: '0px 15px' }}>
									<ProfileSection />
								</li>
								:
								<li className="semiBold font15 pointer">
									<a href="/dang-nhap" className="radius8 lightBg" style={{ padding: "10px 15px 10px 15px" }}>
										Đăng nhập
									</a>
								</li>
						} */}



						{/* <li className="semiBold font15 pointer flexCenter">
							<a href="/" className="radius8 lightBg" style={{ padding: "10px 15px" }}>
								Get Started
							</a>
						</li> */}
					</UlWrapperRight>
				</NavInner>
			</Wrapper>
		</>
	);
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;

export default TopNavbar;

import { APP_CONFIG } from "../config";
import { ApiListResp, ApiResp } from "../types/api-resp.type";
import { HttpApi } from "./http/http.api";


export interface SourceCrawlerLog {
    [key: string]: number
}


export interface Source {
    id?: number;
    is_active: boolean;
    name: string;
    platform: string;
    url: string;
    is_old_post_available?: boolean;
    is_old_to_new?: boolean;
    is_post_available?: boolean;
    is_today?: boolean;
    no_aff?: number;
    no_item?: number;
    no_published_aff?: number;
    no_published_item?: number;
    no_channel?: number;
    no_post?: number;
    no_video?: number;
    crawler_log?: SourceCrawlerLog;
    is_no_item_warning?: boolean;
}

export interface Channel {
    id?: number;
    account_id: number;
    is_active: boolean;
    platform: string;
    name: string;
    sources?: Source[];
    is_last_published_warning?: boolean;
    is_no_published_aff_warning?: boolean;
    is_no_rest_item_warning?: boolean;
    is_no_rest_aff_warning?: boolean;    
    last_published?: number;
    no_failed_today?: number;
    no_published_aff?: number;
    no_rest_aff?: number;
    no_rest_item?: number;
}

export interface Account {
    id?: number;
    platform: string;
    username: string;
    fullname: string;
    password?: string;
    channels?: Channel[];
}

export interface AccountFilter {
    mediaType?: string;
    platform?: string;
}

export interface SourceFilter {
    mediaType?: string;
    platform?: string;
}

export interface CreateChannelSourcePayload {
    source_id: number;
    channel_id: number;
    media_type: string;
    is_today: boolean;
    is_old_post_available: boolean;
    is_old_to_new: boolean;
    is_active: boolean;
}

export interface ChannelSourceCrawlerLog {
    sources: Source[],
    report: {[key: string]: {[key: number]: number}}
}

export interface ChannelLogSummary {
    no_success: number;
    no_upload_aff_failed: number;
    no_upload_failed: number;
    in_queue: number;
    no_download_failed: {[key: string]: number}
}

export interface ChannelLogItem {
    id: number;
    item_id: number | null;
    item_type: string;
    channel_id: number;
    created_at: string;
    exec_result: string | null;
    exec_time: string;
}

export interface ChannelLog {
    summary: ChannelLogSummary;
    log_items: ChannelLogItem[];
}

export const createBulkAccountApi = (accounts: Account[]): Promise<ApiResp<null>> =>
    HttpApi.post<ApiResp<null>>(`${APP_CONFIG.AUTO_TOOL_API_URL}/accounts`, { 'accounts': accounts }).then(({ data }) => data);

export const updateAccountApi = (accountId: number, account: Account): Promise<ApiResp<Account>> =>
    HttpApi.put<ApiResp<Account>>(`${APP_CONFIG.AUTO_TOOL_API_URL}/accounts/${accountId}`, account).then(({ data }) => data);

export const getAccountListApi = (filter: AccountFilter): Promise<ApiListResp<Account>> => {

    const params = [];
    if (filter.mediaType) {
        params.push(`media_type=${filter.mediaType}`)
    }
    if (filter.platform) {
        params.push(`platform=${filter.platform}`)
    }
    
    return HttpApi.get<ApiListResp<Account>>(`${APP_CONFIG.AUTO_TOOL_API_URL}/channels${params.length ? '?' + params.join('&') : ''}`).then(({ data }) => data);
}

export const createChannelSourceApi = (payload: CreateChannelSourcePayload): Promise<ApiListResp<Account>> =>
    HttpApi.post<ApiListResp<Account>>(`${APP_CONFIG.AUTO_TOOL_API_URL}/channels/${payload.channel_id}/sources/${payload.source_id}`, payload).then(({ data }) => data);

export const getChannelPlatformListApi = (): Promise<ApiListResp<string>> => 
    HttpApi.get<ApiListResp<string>>(`${APP_CONFIG.AUTO_TOOL_API_URL}/channels/platforms`).then(({ data }) => data);

export const getAccountPlatformListApi = (): Promise<ApiListResp<string>> => 
    HttpApi.get<ApiListResp<string>>(`${APP_CONFIG.AUTO_TOOL_API_URL}/accounts/platforms`).then(({ data }) => data);

export const getChannelSourceListApi = (channelId: number, mediaType: string): Promise<ApiResp<Channel>> => 
    HttpApi.get<ApiResp<Channel>>(`${APP_CONFIG.AUTO_TOOL_API_URL}/channels/${channelId}/sources?media_type=${mediaType}`).then(({ data }) => data);

// export const getChannelPlatformListApi = (filter: Partial<Account>): Promise<ApiListResp<Account>> => {
//     return HttpApi.get<ApiListResp<Account>>(`${APP_CONFIG.AUTO_TOOL_API_URL}/channels`).then(({ data }) => data);
// }

export const getSourcePlatformListApi = (): Promise<ApiListResp<string>> => 
    HttpApi.get<ApiListResp<string>>(`${APP_CONFIG.AUTO_TOOL_API_URL}/sources/platforms`).then(({ data }) => data);
    
export const getSourceListApi = (filter: SourceFilter): Promise<ApiListResp<Source>> => {

    const params = [];
    if (filter.mediaType) {
        params.push(`media_type=${filter.mediaType}`)
    }
    if (filter.platform) {
        params.push(`platform=${filter.platform}`)
    }
    
    return HttpApi.get<ApiListResp<Source>>(`${APP_CONFIG.AUTO_TOOL_API_URL}/sources${params.length ? '?' + params.join('&') : ''}`).then(({ data }) => data);
}

export const getChannelSourceCrawlerLogApi = (channelId: number, mediaType: string): Promise<ApiResp<ChannelSourceCrawlerLog>> => 
    HttpApi.get<ApiResp<ChannelSourceCrawlerLog>>(`${APP_CONFIG.AUTO_TOOL_API_URL}/channels/${channelId}/sources/logs?media_type=${mediaType}`).then(({ data }) => data);

export const getChannelLogApi = (channelId: number, mediaType: string): Promise<ApiResp<ChannelLog>> => 
    HttpApi.get<ApiResp<ChannelLog>>(`${APP_CONFIG.AUTO_TOOL_API_URL}/channels/${channelId}/logs?media_type=${mediaType}`).then(({ data }) => data);

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EventEntity, EventFilter, getEventByEventTypeAndEventIdApi, getEventListApi, submitEventSupportApi } from "../../apis/event.api";

export interface EventSlice {
    events: EventEntity[]
}

const initialState: EventSlice = {
    events: []
};

export const getEventList = createAsyncThunk('event/list', async (filters: EventFilter) => {
    const resp = await getEventListApi(filters);
    
    return resp.result.items;
});

export const submitEventSupport = createAsyncThunk('event/submitSupport', async (eventId: string) => {
    const resp = await submitEventSupportApi(eventId);
    
    return resp.result;
});

export const getEventByEventTypeAndEventId = createAsyncThunk('event/submitSupport', async (payload: {
    eventType: string,
    eventId: string
}) => {
    const resp = await getEventByEventTypeAndEventIdApi(payload.eventType, payload.eventId);
    
    return resp.result;
});

const eventSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEventList.fulfilled, (state, action) => {
        state.events = action.payload
    });
  },
});

export default eventSlice.reducer;
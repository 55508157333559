// material-ui
import { List, Typography } from '@mui/material';

// project imports
import NavItem from '../navigation/navigation-item.component';
import menuItems from './menu-item.component';
import NavGroup from './menu-group.component';
import { useAppSelector } from '../../hooks/redux.hook';
import { UserRole } from '../../apis/auth.api';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList: React.FC = () => {


    const navItems = menuItems.items.map((item) => {
        return <NavGroup key={item.id} item={item} />;
    });

    return <>{navItems}</>;
};

export default MenuList;

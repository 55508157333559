import { Dayjs } from 'dayjs';
import { APP_CONFIG } from '../config';
import { ApiListResp, ApiResp } from '../types/api-resp.type';
import { HttpApi } from './http/http.api';

export interface RequestType {
    name: string;
    value: string;
}

export interface CreateRequestData {
    title?: string;
    type: string;
    start_time: Date | null | string | Dayjs;
    end_time: Date | null | string | Dayjs;
    note: string;
}

export interface RequestData extends CreateRequestData {
    id: string,
    status: string,
    created_at: string,
    modified_at: Date,
    deleted_at: Date
}

export const createRequest = (createRequestPayload: CreateRequestData): Promise<ApiResp<RequestData>> =>
    HttpApi.post<ApiResp<RequestData>>(`${APP_CONFIG.API_URL}/requests`, { ...createRequestPayload }).then(({ data }) => data);

export const getRequestList = (): Promise<ApiListResp<RequestData>> =>
    HttpApi.get<ApiListResp<RequestData>>(`${APP_CONFIG.API_URL}/requests`).then(({ data }) => data);

export const getRequestTypes = (): Promise<ApiResp<{[key:string]: string}>> =>
    HttpApi.get<ApiResp<{[key:string]: string}>>(`${APP_CONFIG.API_URL}/requests/types`).then(({ data }) => data);
